import React from "react";
import { useRecoilState } from "recoil";
import { createTeeshotAxiosInstance } from "src/http";
import { recoilStates } from "src/recoil/recoilStates";

export default function useRefetchBookings() {
  const [_5, setBookingApplication] = useRecoilState(recoilStates.bookingApplication);

  const refetchBooking = async () => {
    const yearBookingRes = await createTeeshotAxiosInstance().get("/yearBooking/booking");
    if (yearBookingRes?.data?.data) {
      setBookingApplication(yearBookingRes?.data?.data);
    }
  };
  return refetchBooking;
}
