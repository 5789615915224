import { Outlet, useNavigate } from "react-router-dom";
import { ReactNode, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "src/styles/theme";

export default function MainLayout() {
  return (
    <Contianer>
      {/* <TabHeader /> */}
      {/* 임시비활성화 */}
      <Outlet />
    </Contianer>
  );
}

type TabValueType = "year" | "event";
const TabHeader = () => {
  const [tabValue, setTabValue] = useState<TabValueType>("year");
  const navigate = useNavigate();
  return (
    <StyledTabHeader>
      <TabItem
        $isActive={tabValue == "year"}
        onClick={() => {
          setTabValue("year");
          navigate("/year-booking");
        }}
      >
        연간 부킹
      </TabItem>
      <TabItem
        $isActive={tabValue == "event"}
        onClick={() => {
          alert("준비중");
          // setTabValue("event");
          // navigate("/event-booking");
        }}
      >
        월 부킹(이벤트/행사)
      </TabItem>
    </StyledTabHeader>
  );
};
// const
const Contianer = styled.div`
  /* height: 100%; */
  /* flex: 1;
  display: flex;
  flex-direction: column; */
`;
const StyledTabHeader = styled.header`
  display: flex;
`;
const TabItem = styled.div<{ $isActive?: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7.5px;
  font-weight: bold;
  ${(p) =>
    p.$isActive &&
    css`
      color: ${colors.green.first};
      border-bottom: 3px solid ${colors.green.first};
    `}
`;
const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
