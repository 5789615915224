import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import { userStepStatus } from "src/types";

export default function useUserStepStatus() {
  const [bookingStatusName, setBookingStatusName] = useState<userStepStatus | null>(null);
  const [group] = useRecoilState(recoilStates.group);

  const [bookingApplication] = useRecoilState(recoilStates.bookingApplication);

  useEffect(() => {
    (() => {
      if (!group.id) {
        setBookingStatusName("noGroup");
        // 그룹생선 전
        return;
      }

      if (bookingApplication?.clubRels?.every((cr) => [7].includes(cr.year_booking_status_id))) {
        setBookingStatusName("rejectedAllProposal");
        // 모든 지원건에 대해 거절 됨
        return;
      }

      if (
        !bookingApplication || // 부킹 신청 전
        bookingApplication?.clubRels?.every((cr) => {
          return [2, 5, 7, 8].includes(cr.year_booking_status_id);
        }) // 이전 신청건에 대해 모든 골프장에 거절(7) 혹은 계약만료(8) 혹은 미선정(5) 혹은 지원자취소(2)
      ) {
        setBookingStatusName("noBooking");
        return;
      }

      if (bookingApplication?.clubRels?.some((cr) => cr.year_booking_status_id === 6)) {
        setBookingStatusName("completeDeposit");
        return;
        // 입금완료확인
      }

      if (
        bookingApplication?.clubRels?.some((cr) => cr.year_booking_status_id === 4)
        //보증금 입금대기(사용자 확정)
      ) {
        setBookingStatusName("waitingDeposit");
        return;
      }

      if (
        bookingApplication?.clubRels?.every((cr) => [1, 7].includes(cr.year_booking_status_id)) // 거절 혹은 조건제시 이전
        // 부킹신청 직후(골프장 조건제시 이전)
      ) {
        setBookingStatusName("waitingProposal");
        return;
      }
      setBookingStatusName("hasProposal");
      // set to "error"
    })();
    return () => {};
  }, [bookingApplication, group]);

  return bookingStatusName;
}
