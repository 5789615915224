import { useEffect, useState } from "react";
import { createTeeshotAxiosInstance } from "src/http";
import { sleep } from "src/utils";
import useAlert from "./useAlert";

type GroupType = { id: number; name: string };

function useGroupTypes() {
  const [groupTypes, setGroupTypes] = useState<GroupType[]>();

  const alert = useAlert();
  useEffect(() => {
    (async () => {
      try {
        const res = await createTeeshotAxiosInstance().get("/yearBooking/group/character");
        if (res.data.success === false) {
          throw new Error();
        }
        setGroupTypes(res.data.data);
      } catch (e) {
        console.log(" e:", e);
        alert("단체성격 조회 오류");
      }
    })();
  }, []);

  return groupTypes;
}

export default useGroupTypes;
