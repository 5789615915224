import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "src/styles/theme";
import DoubleButton from "../DoubleButton";
import { ClubType, SelectedClubType } from "src/types";
import ImgZoomContainer from "../ImgZoomContainer";

export default function AnnouncementAgreePopupContents(props: {
  preference: number;
  announcementShowingClub?: ClubType;
  setSelectedClubs: React.Dispatch<React.SetStateAction<SelectedClubType[] | undefined>>;
  closeParent?: () => void;
  closeMe?: () => void;
}) {
  const [isAnnouncementChecked, setIsAnnouncementChecked] = useState(false);
  const clubPreference = props.preference;

  const handleClickSelectSubmit = () => {
    props.setSelectedClubs((clubs) => {
      if (clubs?.some((c) => c.id === props?.announcementShowingClub?.id)) {
        return [...clubs];
      }
      const filtered = clubs?.filter((c) => +c.preference !== clubPreference);

      return [
        ...(filtered ?? []),
        { preference: clubPreference, ...(props.announcementShowingClub as any) },
      ];
    });
    props.closeMe && props.closeMe();
    props.closeParent && props.closeParent();
  };
  return (
    <>
      <Container className="flex-1 d-flex fd-c gap-2">
        <div className="flex-1">
          <ImgZoomContainer
            imgUrl={props.announcementShowingClub?.recruitment_notice_img_url ?? ""}
          />
        </div>
        <div className="check-line">
          <input
            type="checkbox"
            checked={isAnnouncementChecked}
            onChange={(e) => {
              setIsAnnouncementChecked(e.target.checked);
            }}
          />
          <p>
            {props?.announcementShowingClub?.name} 골프장의 연간 단체팀 이용 수칙을 이해하였고 이에
            동의합니다
          </p>
        </div>
        <DoubleButton
          nagativeMargin={true}
          left={{
            name: "취소",
            onClick: () => {
              props.closeMe && props.closeMe();
              // props.onCloseButtonClick && props.onCloseButtonClick();
            },
            themeColor: "lightGray",
            widthRatio: 1,
          }}
          right={{
            name: "골프장 선택",
            onClick: () => {
              handleClickSelectSubmit();
            },
            themeColor: "green",
            widthRatio: 3,
            buttonCompProps: { disabled: !isAnnouncementChecked },
          }}
        />
      </Container>
    </>
  );
}

const Container = styled.div`
  /* height:100%; */
  /* overflow-y: auto; */
  .name-line {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    > div {
      &:nth-of-type(1) {
      }
      &:nth-of-type(2) {
        font-size: 14px;
        color: ${colors.gray.first};
      }
    }
  }
  .req-img {
    width: 100%;
  }
  .check-line {
    input[type="checkbox"] {
      width: 30px;
    }
    display: flex;
    gap: 5px;
    font-size: 14px;
  }
`;
