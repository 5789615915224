import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";
import Button from "../Button";
import { LuEraser } from "react-icons/lu";
function SignModalContent(props: {
  setSignBase64: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  useEffect(() => {
    props.setSignBase64(null);
  }, []);

  const ref = useRef<SignatureCanvas>(null);

  return (
    <Container>
      <span className="sign-bg">
        <SignatureCanvas
          penColor="black"
          backgroundColor="transparent"
          ref={ref}
          canvasProps={{
            width: 250,
            height: 250,
            className: "sigCanvas",
          }}
          onEnd={(e) => {
            const dd = ref?.current?.toDataURL();
            props.setSignBase64(dd as string);
          }}
        />
      </span>
      <Button
        className="mt-2 mb-2  del-btn"
        $colorTheme="greenReverse"
        $round
        $outline
        onClick={() => {
          props.setSignBase64(null);
          ref.current?.clear();
        }}
      >
        <LuEraser size={18} /> 다시쓰기
      </Button>
    </Container>
  );
}

export default SignModalContent;
const Container = styled.div`
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .sign-bg {
    background-color: #f2f2f2;
    border: 1px solid gray;
    /* position: relative; */
  }
  .del-btn {
    /* position: absolute; */
    right: 10px;
    bottom: 0;
    align-self: flex-end;
    display: flex;
    gap: 5px;
    font-weight: bold;
  }
`;
