import React, { ReactNode } from "react";
import Button from "../Button";
import styled from "styled-components";
import { MdGroups } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaCalendarAlt } from "react-icons/fa";
import { AiFillDatabase } from "react-icons/ai";
import { colors } from "src/styles/theme";
import { useNavigate } from "react-router-dom";
import { IoReceiptOutline } from "react-icons/io5";
import { NavMenuNames } from "src/types";
import { IoMdPersonAdd } from "react-icons/io";
import { FaHandshake } from "react-icons/fa";
import { FaCoins } from "react-icons/fa";

const childDataObject: Partial<{
  [key in NavMenuNames]: {
    icon?: ReactNode;
    name?: ReactNode;
    isNav?: boolean;
    navPath?: string;
  };
}> = {
  group: {
    icon: <AiFillDatabase size={"22"} />,
    name: "단체관리",
    isNav: true,
    navPath: "/year-booking/group-manage",
  },
  member: {
    icon: <IoMdPersonAdd size={"22"} />,
    name: "멤버관리",
    isNav: true,
    navPath: "/year-booking/group-members/list",
  },
  bookingRegisterResult: {
    icon: <IoReceiptOutline size={"22"} />,
    name: "신청내역",
    isNav: true,
    navPath: "/year-booking/booking-register/result",
  },
  bookingProposalResult: {
    icon: <FaHandshake size={"22"} />,
    name: "협약내용",
    isNav: true,
    // navPath: "/year-booking?bookingCondition=1",
    navPath: "/year-booking/condition",
  },
  deposit: {
    icon: <FaCoins size={"22"} />,
    name: "보증금",
    isNav: true,
    navPath: "/year-booking/deposit",
  },
};

export default function MainNavBtns(props: { childNames?: (keyof typeof childDataObject)[] }) {
  const navigate = useNavigate();

  if (!props.childNames?.length) {
    return <></>;
  }

  return (
    <MainContainer className="mt-5 flex-1 d-flex fd-c jc-e gap-2">
      <h3>메뉴 탐색</h3>
      <div className="btns-grid-container">
        {props.childNames?.map((c, idx) => {
          const d = childDataObject[c];
          return (
            <Child
              key={`${d?.name}-${idx}`}
              name={d?.name}
              icon={d?.icon}
              onClick={() => {
                if (d?.isNav && d?.navPath) {
                  navigate(d?.navPath);
                }
              }}
            ></Child>
          );
        })}
      </div>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  h3 {
    font-weight: bold;
    font-size: 18px;
  }

  .btns-grid-container {
    display: grid;
    gap: 10px;

    grid-template-columns: 1fr 1fr;
    @media only screen and (min-width: 600px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

function Child(props: { name: ReactNode; icon: ReactNode; onClick?: () => any }) {
  return (
    <Button
      // style={{ maxHeight: "50px", fontSize:"18px" }}
      $buttonSize="md"
      className="fs-5"
      $colorTheme="lightGray3"
      onClick={props.onClick}
      $round
    >
      <div className="d-flex gap-1 ai-c flex-1 jc-sb bold">
        <div>{props.name}</div>
        <div className="pt-1">{props.icon}</div>
      </div>
    </Button>
  );
}
