import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selector, useRecoilState, useRecoilValue } from "recoil";
import {
  Button,
  ContentBox,
  Divider,
  DoubleButton,
  Input,
  Label,
  Loading,
  SreenCoverLoading,
  usePopupPage,
} from "src/components";
import { ApplyPaperPopupContents } from "src/components/PopupPageContents";
import { useAlert, useConfirm } from "src/hooks";
import { createTeeshotAxiosInstance } from "src/http";
import { recoilStates } from "src/recoil/recoilStates";
import STATIC_DATA from "src/staticData";
import { colors } from "src/styles/theme";
import { parseJsonStr, sleep } from "src/utils";
import styled from "styled-components";

type Sc = {
  months: { name: string }[];
  weeks: { name: string }[];
  days: { name: string }[];
  time: { name: string };
};
type RegisterDataType = {
  submitUser: {
    id: number;
    name: string;
    phone_number: string;
  };
  groupName: string;
  location: string;
  clubs: { preference: number; name: string }[];
  teamCount: { count: number };
  schedule: {
    first: Sc;
    second?: Sc;
  };
  playReq?: {
    caddyOpt?: { name: string };
    caddyCount?: number;
    cart5?: { count: number };
    cart2?: { count: number };
  };
  playType?: {
    name: string;
  };
  teeType?: {
    name: string;
  };
  etcReq?: { name: string }[];
  payment?: {
    green?: { id: number };
    cart?: { id: number };
    caddy?: { id: number };
    snackBar: { id: number };
    meal: { id: number };
  };
  addReq?: string;
};

export default function YearBookingRegisterResult() {
  const navigate = useNavigate();
  const [group] = useRecoilState(recoilStates.group);
  const [bookingApplication] = useRecoilState(recoilStates.bookingApplication);
  const [isCoverLoading, setIsCoverLoading] = useState(false);

  const alert = useAlert();

  useEffect(() => {
    if (!bookingApplication?.id) {
      (async () => {
        alert({ content: "부킹신청 내역이 없습니다.", onClose: () => alert.resume() });
        await alert.pause();

        navigate("/");
      })();
    }
  }, []);

  const confirm = useConfirm();
  // const { PopupComponent: ApplyPopupComp, openPopup: openApplyPopupComp } = usePopupPage("apply");

  return (
    <Container className="flex-1 d-flex fd-c gap-3">
      <h3 className="fs-5 fw-b">{group?.name}</h3>
      <div className="d-flex fd-c gap-2">
        <Label className="bold" content={"희망 골프장"} />
        {bookingApplication?.clubRels.map((c) => {
          return (
            <div className="d-flex fd-c gap-1">
              <div className="d-flex gap-3" key={c.name}>
                <Input
                  attr={{
                    readOnly: true,
                    value: c.preference,
                    style: { width: 30, textAlign: "center" },
                  }}
                />
                <Input
                  boxClassName="flex-1"
                  attr={{ readOnly: true, value: c.name, style: { flex: 1 } }}
                />
                {/* <Button
                        // className="as-c"
                        $round
                        $colorTheme="black"
                        // $buttonSize="md"
                        onClick={() => {
                          openApplyPopupComp();
                        }}
                      >
                        신청서 보기
                      </Button> */}
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-flex fd-c gap-1">
        <Label className="bold" content={"신청 팀수"} />
        <div className="ml-2">{bookingApplication?.team_amount} 팀</div>
      </div>
      <div className="d-flex fd-c gap-1">
        <Label className="bold" content={"신청일자"} />
        <ContentBox
          content={
            <div className="d-flex fd-c gap-2">
              {bookingApplication?.hopeDates?.map((hd) => {
                // hd.preference;
                return (
                  <div key={`hd-${hd.id}`} className="cols-container-55">
                    <div>{hd.preference} 지망</div>
                    <div>
                      <div className="ml-2 fw-n">
                        {JSON.parse(hd?.month_arr_json_str ?? [])
                          .map((m: number) => `${m}월`)
                          .join(", ")}
                      </div>
                      <div className="ml-2 fw-n">
                        {JSON.parse(hd?.week_arr_json_str ?? [])
                          .map((w: number) => `${w}주차`)
                          .join(", ")}{" "}
                        {JSON.parse(hd?.day_arr_json_str ?? [])
                          .map((d: string) => `${d}요일`)
                          .join(", ")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          }
        />
      </div>

      <Divider />
      <div className="d-flex gap-2">
        <Label className="bold" content={"객단가"} />
        <div className="">
          {(() => {
            const priceId = bookingApplication?.price_per_person_id;
            if (!priceId) {
              return " - ";
            }
            if (Number(priceId) && Number(priceId) < 5) {
              return `${priceId} 만원`;
            }
            return `${priceId} 만원 이상`;
          })()}
        </div>
      </div>
      <Divider />

      <div className="d-flex fd-c gap-1">
        <Label className="bold" content={"시설이용"} />
        <ContentBox
          content={
            <div className="d-flex fd-c gap-2">
              <div className="cols-container-55">
                <div>결제</div>
                <div className="fw-n">
                  {STATIC_DATA.PAY_TYPE_DATA.find(
                    (d) => d.id === bookingApplication?.payment_type_id
                  )?.name || "  "}
                </div>
              </div>
              <div className="cols-container-55">
                <div>식사</div>
                <div className="fw-n">
                  {STATIC_DATA.MEAL_TYPE_DATA.find((d) => d.id === bookingApplication?.meal_type_id)
                    ?.name || " - "}
                </div>
              </div>
              <div className="cols-container-55">
                <div>프로샵</div>
                <div className="fw-n">{bookingApplication?.proshop_item || " - "}</div>
              </div>
            </div>
          }
        />
      </div>
      <div className="d-flex fd-c gap-1">
        <Label className="bold" content={"경기 및 시상"} />
        <ContentBox
          content={
            <div className="d-flex fd-c gap-2">
              <div className="cols-container-70">
                <div>경기방식</div>
                <div className="fw-n">
                  {STATIC_DATA.GAME_TYPE_DATA.find((d) => d.id === bookingApplication?.game_type_id)
                    ?.name || "  "}
                </div>
              </div>
              <div className="cols-container-70">
                <div>티사용</div>
                <div className="fw-n">
                  {STATIC_DATA.TEEBOX_TYPE_DATA.find(
                    (d) => d.id === bookingApplication?.teebox_type_id
                  )?.name || " - "}
                </div>
              </div>
              <div className="cols-container-70">
                <div>시상내용</div>
                <div className="fw-n">
                  {(() => {
                    const awards = parseJsonStr(bookingApplication?.award_ids_arr_json_str || "");
                    if (!awards || !awards.length) {
                      return "";
                    }

                    return STATIC_DATA.AWARDS_DATA.filter((d) => {
                      return awards.includes(d.id);
                    })
                      .map((x) => x.name)
                      .join(", ");
                  })()}
                </div>
              </div>
              <div className="cols-container-70">
                <div>요청사항</div>
                <div className="fw-n">
                  {bookingApplication?.etcRequirements?.map((e) => e.name)?.join(", ") || " - "}
                </div>
              </div>
            </div>
          }
        />
      </div>

      <div className="d-flex fd-c gap-2">
        <div className="cols-container-55 applier">
          <Label className="bold " content="신청자" />

          <div className="d-flex gap-1">
            <div>
              {group?.members?.find((m) => m?.id === bookingApplication?.applying_member_id)?.name}
            </div>
            <div>
              {
                group?.members?.find((m) => m?.id === bookingApplication?.applying_member_id)
                  ?.phone_number
              }
            </div>
          </div>
        </div>
      </div>

      {/* <ApplyPopupComp type="x" headerMsg="신청서" content={<ApplyPaperPopupContents />} /> */}
      <Button
        $round
        className="mt-6"
        $colorTheme="red"
        $buttonSize="md"
        onClick={() => {
          confirm({
            title: "부킹취소",
            content: "정말로 부킹신청을 취소하시겠습니까?",
            onConfirm: () => {
              (async () => {
                try {
                  setIsCoverLoading(true);
                  await createTeeshotAxiosInstance().post("/yearBooking/booking/user-cancel", {
                    bookingId: bookingApplication?.id,
                  });
                } catch (e) {
                  console.log("e :", e);
                  setIsCoverLoading(false);
                  alert({
                    content: "취소요청 실패",
                    onClose: () => {
                      alert.resume();
                    },
                  });
                  await alert.pause();
                } finally {
                  setIsCoverLoading(false);
                  navigate("/");
                  window.location.reload();
                }
              })();
            },
          });
        }}
      >
        부킹신청 취소하기
      </Button>
      {isCoverLoading && <SreenCoverLoading />}
    </Container>
  );
}

const Container = styled.div`
  * {
    word-wrap: break-word;
    white-space: break-spaces;
    word-break: break-all;
  }
  label {
  }
  /* .dates-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  } */

  .applier {
    background-color: ${colors.gray.third};
    padding: 5px;
  }
  .cols-container-55 {
    display: flex;
    gap: 5px;
    > :first-child {
      flex-basis: 55px;
    }
  }
  .cols-container-70 {
    display: flex;
    gap: 5px;
    > :first-child {
      flex-basis: 70px;
      flex-shrink:0;
    }
  }
`;
