import { ReactNode } from "react";
import { useModal } from "src/context/ModalContext";

export default function useConfirm() {
  const md = useModal();

  const confirm = async (args: { title?: string; content: ReactNode; onConfirm?: () => any }) => {
    if (md?.openModal) {
      md?.openModal(
        <md.Modal
          title={args.title || "확인"}
          content={args.content}
          onConfirm={() => {
            args.onConfirm && args.onConfirm();
          }}
          btnType="confirm-cancel"
        />
      );
    }
  };

  return confirm;
}
