import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { DoubleButton, Input, Modal, SreenCoverLoading } from "src/components";
import { usePopupLocation } from "src/components/PopupLocation";
import { usePopupSelectV2 } from "src/components/PopupSelect";
import { useModal } from "src/context/ModalContext";
import {
  useAlert,
  useBackListener,
  useGroupLocation,
  useGroupTypes,
  useUserStepStatus,
} from "src/hooks";
import { createTeeshotAxiosInstance } from "src/http";
import { recoilStates } from "src/recoil/recoilStates";
import { CheckSubmitType } from "src/types";
import { checkSubmitData, fileToBase64, sleep } from "src/utils";
import styled from "styled-components";
// import { useInput } from "src/components/Input";

export default function YearGroupRegister() {
  const [user] = useRecoilState(recoilStates.user);
  const { value: groupNameValue, onChange: onChangeGroupName } = Input.hooks.useInput("");
  const { value: proposerNameValue, onChange: onChangeProposerName } = Input.hooks.useInput(
    user.realname || user.username
  );
  const { value: contactNumberValue, onChange: onChangeContactNumberValue } = Input.hooks.useInput(
    user.phone,
    new RegExp("^[0-9]*$")
  );
  const { value: groupSnsUrlValue, onChange: onChangeGroupSnsUrlValue } = Input.hooks.useInput("");
  // const { value: groupSnsUrlValue, onChange: onChangeGroupSnsUrlValue } = Input.hooks.("");
  const { base64, fileName, handleFileInput, incodeLoading, previewTempUrl } =
    Input.hooks.useFileInput();
  const { value: groupIntroductionValue, onChange: onChangeGroupIntroductionValue } =
    Input.hooks.useInput("");
  const groupTypes = useGroupTypes();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    PopupSelect: GroupTypePopupSelect,
    activeIds: activeGroupTypeIds,
    propsToInject: propsToInjectFirst,
  } = usePopupSelectV2();

  const groupLocations = useGroupLocation();

  const { PopupLocation, propsToInject, activeChildId, activeParentId } = usePopupLocation();

  const [group, setGroup] = useRecoilState(recoilStates.group);
  const { value: memberCountValue, onChange: handleMemberCountChange } = Input.hooks.useInput();

  const userStep = useUserStepStatus();

  const [count, setCount] = useState(0);
  useEffect(() => {
    (async () => {
      if (!userStep) {
        return;
      }
      if (count > 1) {
        // TODO: refoctor 그룹을 만들자마자
        return;
      }
      if (userStep !== "noGroup") {
        alert({
          content: "이미 그룹을 만들었습니다.",
          onClose: () => {
            alert.resume();
          },
        });
        await alert.pause();
        alert.resume();
        navigate("/");
      }
    })();
    setCount((c) => c + 1);
  }, [userStep]);

  const submitData = {
    groupNameValue: groupNameValue?.trim(),
    proposerNameValue: proposerNameValue?.trim(),
    memberCountValue: memberCountValue,
    contactNumberValue: contactNumberValue?.trim(),
    groupSnsUrlValue: groupSnsUrlValue?.trim(),
    groupIntroductionValue: groupIntroductionValue?.trim(),
    activeGroupTypeId: activeGroupTypeIds?.[0],
    activeDistrictId: (() => {
      if (!activeParentId) {
        return null;
      }
      const adjustedChildId = (() => {
        if (!activeChildId) {
          return "000";
        }
        if (activeChildId < 100) {
          return `0${activeChildId}`;
        }
        if (activeChildId < 10) {
          return `00${activeChildId}`;
        }
        return `${activeChildId}`;
      })();
      return `${activeParentId}${adjustedChildId}`;
    })(),
    groupImgBase64: base64,
  };

  const checkSubmitDataObj: CheckSubmitType[] = [
    {
      idToFocus: "group-name",
      name: "단체명",
      checkCondition: Boolean(submitData?.groupNameValue),
    },
    {
      idToFocus: "member-count",
      name: "회원수",
      checkCondition: Boolean(submitData?.memberCountValue),
      additionalMsg: "숫자만 입력해주세요.",
    },
    {
      idToFocus: "group-char",
      name: "단체성격",
      checkCondition: Boolean(submitData?.activeGroupTypeId),
    },
    {
      idToFocus: "group-location",
      name: "활동지역",
      checkCondition: Boolean(submitData?.activeDistrictId),
    },
    {
      idToFocus: "proposer-name",
      name: "신청자(이름)",
      checkCondition: Boolean(submitData?.proposerNameValue),
    },
    {
      idToFocus: "contact-num",
      name: "신청인 휴대폰",
      checkCondition: Boolean(submitData?.contactNumberValue),
    },
  ];
  const alert = useAlert();

  return (
    <>
      <Container className="flex-1 d-flex fd-c gap-3 flex-1 jc-sb flex-1">
        <Input
          label="단체명"
          isEssential={true}
          attr={{
            id: "group-name",
            value: groupNameValue,
            onChange: onChangeGroupName,
            placeholder: "단체이름을 입력하세요.",
            tabIndex: 1,
          }}
        />
        <Input
          label="회원수"
          isEssential={true}
          attr={{
            id: "member-count",
            type: "number",
            value: memberCountValue,
            onChange: handleMemberCountChange,
            placeholder: "예시) 50",
            tabIndex: 2,
          }}
        />

        <GroupTypePopupSelect
          items={groupTypes ?? []}
          placeholder={"선택해주세요"}
          inputId="group-char"
          label={"단체성격"}
          tabIndex={3}
          isEssential={true}
          {...propsToInjectFirst}
        />
        {groupLocations && (
          <PopupLocation
            {...propsToInject}
            inputId={"group-location"}
            tabIndex={4}
            items={groupLocations}
            label="활동지역"
            isEssential={true}
            placeholder="선택해주세요"
          />
        )}
        <Input
          label="신청자(이름)"
          isEssential={true}
          attr={{ id: "proposer-name", value: proposerNameValue, onChange: onChangeProposerName }}
        />
        {/*TODO 숫자 타입만들어가게만들기 대시 안들어가게*/}
        <Input
          label="신청인 휴대폰"
          isEssential={true}
          attr={{
            id: "contact-num",
            type: "number",
            value: contactNumberValue,
            onChange: onChangeContactNumberValue,
            placeholder: "예시) 01012345678",
          }}
        />
        <Input
          label="단체 카페/밴드 주소"
          isEssential={false}
          attr={{
            value: groupSnsUrlValue,
            onChange: onChangeGroupSnsUrlValue,
            placeholder: "예시) http://cafe.naver.com/lovegolf, 네이버카페 골프사랑",
          }}
        />
        <Input
          label="단체 소개"
          isEssential={false}
          textArea
          textAreaAttr={{
            value: groupIntroductionValue,
            onChange: onChangeGroupIntroductionValue as any,
            placeholder: "예시) 사내 골프 동호회입니다.",
          }}
        />
        <Input
          label="단체 로고"
          fileName={fileName}
          imgUrl={previewTempUrl ?? ""}
          attr={{
            type: "file",
            onChange: (e) => {
              handleFileInput(e);
            },
          }}
        />
        <DoubleButton
          nagativeMargin={true}
          left={{
            name: "취소",
            themeColor: "lightGray",
            onClick: () => {
              navigate(-1);
            },
            widthRatio: 1,
          }}
          right={{
            name: "다음",
            themeColor: "green",
            onClick: () => {
              (async () => {
                const checkResult = checkSubmitData(checkSubmitDataObj);
                if (!checkResult.isOk) {
                  alert({
                    content: (
                      <div className="d-flex fd-c gap-2">
                        <p>
                          [ <span className="bold">{checkResult.msg}</span> ] 을(를) 입력해주세요.
                        </p>
                        {checkResult.additionalMsg ? <>{checkResult.additionalMsg}</> : null}
                      </div>
                    ),
                    onClose: checkResult.focusTarget,
                  });
                  return;
                }
                setSubmitLoading(true);

                try {
                  const res = await createTeeshotAxiosInstance().post("/yearBooking/group", {
                    submitData: submitData,
                  });
                  if (res.data.success === false) {
                    throw new Error();
                  }
                  const groupResult = await createTeeshotAxiosInstance().get("/yearBooking/group");
                  const groupResultData = groupResult?.data?.data;

                  if (!groupResultData?.group || !groupResultData?.members) {
                    alert({
                      content: "그룹정보 조회오류",
                      onClose: () => {
                        window.location.reload();
                      },
                    });
                    return;
                  }
                  setGroup({
                    ...groupResult.data.data.group,
                    members: groupResult.data.data.members,
                  });
                  alert({
                    title: "단체등록 완료",
                    content: (
                      <>
                        <div className="pd-3 d-flex fd-c jc-c gap-1 flex-1">
                          <p>단체등록이 완료되었습니다.</p>
                          <p>희망하시는 골프장을 3개까지 신청하세요~!</p>
                        </div>
                      </>
                    ),
                    onClose: () => {
                      alert.resume();
                    },
                  });
                  await alert.pause();
                } catch (e) {
                  setSubmitLoading(false);
                  alert({
                    content: "단체 생성 실패",
                    onClose: () => {
                      alert.resume();
                    },
                  });
                  await alert.pause();
                } finally {
                  setSubmitLoading(false);
                  navigate(`/year-booking`);
                }
              })();
            },
            widthRatio: 1,
          }}
        />
      </Container>
      {(submitLoading || incodeLoading) && <SreenCoverLoading />}
    </>
  );
}

const Container = styled.div`
  height: 100%;
`;
