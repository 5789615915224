import { RecoilRoot } from "recoil";
import Router from "./Router";
import GlobalStyle from "./styles/GlobalStyles";
import InitRecoil from "./recoil/InitRecoil";
import { CacheBuster, Meta } from "./components";
import { ModalProvider } from "./context/ModalContext";

function App() {
  return (
    <>
      <Meta />
      <CacheBuster>
        <RecoilRoot>
          <ModalProvider>
            <InitRecoil />
            <GlobalStyle />
            <Router />
          </ModalProvider>
        </RecoilRoot>
      </CacheBuster>
    </>
  );
}

export default App;
