import React, { ReactNode, useState, useRef, useCallback } from "react";
import { useModal } from "src/context/ModalContext";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";
import { Button } from "src/components";
import { LuEraser } from "react-icons/lu";


//TODO: 추후 작성..
export default function useSign() {
  return null
  const md = useModal();

  const [signBase64, setSignBase64] = useState<string | null>(null);

  // const callSignModal = async (args: { title?: string; onConfirm?: () => any }) => {
  //   setSignBase64(null);
  //   console.log("Boolean(signBase64) :", Boolean(signBase64));
  //   if (md?.openModal) {
  //     md?.openModal(
  //       <md.Modal
  //         title={args.title || "확인"}
  //         // content={args.content}
  //         disabledConfirmBtn={!Boolean(signBase64)}
  //         // rejectConfirmCondition={!Boolean(signBase64)}
  //         // rejectConfirmMsg="서명을 입력하세요"
  //         content={<SignModalContent setSignBase64={setSignBase64} />}
  //         onConfirm={() => {
  //           args.onConfirm && args.onConfirm();
  //         }}
  //         btnType="confirm-cancel"
  //       />
  //     );
  //   }
  // };

  const callSignModal = async (args: { title?: string; onConfirm?: () => any }) => {
    setSignBase64(null);
    if (md?.openModal) {
      md?.openModal(
        <md.Modal
          title={args.title || "확인"}
          // content={args.content}
          // disabledConfirmBtn={!Boolean(signBase64)}
          // rejectConfirmCondition={!Boolean(signBase64)}
          // rejectConfirmMsg="서명을 입력하세요"
          content={
            <>
              <SignModalContent setSignBase64={setSignBase64} />
            </>
          }
          onConfirm={() => {
            // if (!signBase64) {
            //   alert("empty...");
            //   return;
            // }
            args.onConfirm && args.onConfirm();
          }}
          btnType="confirm-cancel"
        />
      );
    }
  };

  return { callSignModal, signBase64 };
}

function SignModalContent(props: {
  setSignBase64: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const ref = useRef<SignatureCanvas>(null);

  return (
    <Container>
      <span className="sign-bg">
        <SignatureCanvas
          penColor="black"
          backgroundColor="transparent"
          ref={ref}
          canvasProps={{
            width: 250,
            height: 250,
            className: "sigCanvas",
          }}
          onEnd={(e) => {
            const dd = ref?.current?.toDataURL();
            props.setSignBase64(dd as string);
          }}
        />
      </span>
      <Button
        className="mt-2 mb-2  del-btn"
        $colorTheme="greenReverse"
        $round
        $outline
        onClick={() => {
          props.setSignBase64(null);
          ref.current?.clear();
        }}
      >
        <LuEraser size={18} /> 다시쓰기
      </Button>
    </Container>
  );
}

const Container = styled.div`
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .sign-bg {
    background-color: #f2f2f2;
    border: 1px solid gray;
    /* position: relative; */
  }
  .del-btn {
    /* position: absolute; */
    right: 10px;
    bottom: 0;
    align-self: flex-end;
    display: flex;
    gap: 5px;
    font-weight: bold;
  }
`;
