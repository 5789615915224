import { useState } from "react";
import { CheckSubmitType } from "src/types";

export default function checkSubmitData(checkSubmitDataObj: CheckSubmitType[]) {
  let msg = "";
  let additionalMsg = "";
  let targetElem: any;

  if (
    checkSubmitDataObj.some((d) => {
      if (d.checkCondition === false) {
        targetElem = document.querySelector(`#${d.idToFocus}`);
        msg = d.name;
        if (d.additionalMsg) {
          additionalMsg = d.additionalMsg;
        }
        return true;
      }
      return false;
    })
  ) {
    const focusTarget = () => {
      if (!focusTarget) {
        return;
      }
      targetElem?.scrollIntoView({ block: "center" });
      if (typeof targetElem?.focus === "function") {
        targetElem?.focus();
      }
    };
    return { isOk: false, msg, focusTarget, additionalMsg };
  }
  return { isOk: true };
}
