import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { selector, useRecoilState, useRecoilValue } from "recoil";
import {
  ContentBox,
  FindInputButton,
  Label,
  SreenCoverLoading,
  useRadioBoxGroup,
  Input,
  DoubleButton,
  usePopupPage,
  Modal,
} from "src/components";
// TODO: 모달컴포넌트 변경
import {
  AnnouncementAgreePopupContents,
  ClubFindPopupContents,
} from "src/components/PopupPageContents";
import { usePopupSelectV2 } from "src/components/PopupSelect";
import SignModalContent from "src/components/YearBooking/SignModalContent";

import { useAlert, useGroupData, useGroupLocation, useUserStepStatus } from "src/hooks";
import { createTeeshotAxiosInstance } from "src/http";
import { recoilStates } from "src/recoil/recoilStates";
import { bookingDummys } from "src/services/dummys";
import { colors } from "src/styles/theme";
import { CheckSubmitType, ClubType, SelectedClubType } from "src/types";
import { sleep, checkSubmitData } from "src/utils";
import styled from "styled-components";

const times = [
  { id: 1, name: "06시대", str: "0600" },
  { id: 2, name: "07시대", str: "0700" },
  { id: 3, name: "08시대", str: "0800" },
  { id: 4, name: "09시대", str: "0900" },
  { id: 5, name: "10시대", str: "1000" },
  { id: 6, name: "11시대", str: "1100" },
  { id: 7, name: "12시대", str: "1200" },
  { id: 8, name: "13시대", str: "1300" },
  { id: 9, name: "14시대", str: "1400" },
  { id: 10, name: "15시대", str: "1500" },
  { id: 11, name: "16시대", str: "1600" },
  { id: 12, name: "17시대", str: "1700" },
  { id: 13, name: "18시대", str: "1800" },
  { id: 14, name: "19시대", str: "1900" },
  { id: 15, name: "20시대", str: "2000" },
  { id: 16, name: "21시대", str: "2100" },
];

const days = [
  { id: 1, name: "월" },
  { id: 2, name: "화" },
  { id: 3, name: "수" },
  { id: 4, name: "목" },
  { id: 5, name: "금" },
  { id: 6, name: "토" },
  { id: 7, name: "일" },
];

export default function YearBookingRegister() {
  const lc = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(lc.search);
  // const groupData = useGroupData(Number(groupId ?? 0));
  const [isGroupDataLoading, setIsGroupDataLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const groupLocations = useGroupLocation();
  const [group] = useRecoilState(recoilStates.group);
  const [user] = useRecoilState(recoilStates.user);
  const [bookingApplication] = useRecoilState(recoilStates.bookingApplication);

  useEffect(() => {
    if (
      // groupData &&

      groupLocations
    ) {
      setIsGroupDataLoading(false);
    }
  }, [
    // groupData,
    groupLocations,
  ]);

  const userStep = useUserStepStatus();

  useEffect(() => {
    (async () => {
      if (!userStep) {
        return;
      }

      if (userStep !== "noBooking" && userStep !== "rejectedAllProposal") {
        alert({
          content: "이미 부킹을 신청하셨습니다.",
          onClose: () => {
            alert.resume();
          },
        });
        await alert.pause();
        navigate("/");
      }
    })();
  }, [bookingApplication, userStep]);

  const [selectedClubs, setSelectedClubs] = useState<SelectedClubType[]>();

  const [announcementShowingClub, setAnnouncementShowingClub] = useState<ClubType>();

  const [isSignModalOpened, setIsSignModalOpened] = useState(false);
  const [signBase64, setSignBase64] = useState<string | null>(null);

  const {
    PopupComponent: AnnouncePopupComp,
    openPopup: openAnnouncePopup,
    closePopup: closeAnnounce,
  } = usePopupPage("announcement");

  const {
    PopupComponent: ClubFindPopupComp,
    openPopup: openClubSearchPopup,
    closePopup: closeClubSearchPopup,
  } = usePopupPage("clubSearch");

  const { RadioBoxGroup: TeamRadioBoxGroup, activeIds: selectedTeamCountIds } = useRadioBoxGroup({
    items: [
      { id: 2, name: "2팀" },
      { id: 3, name: "3팀" },
      { id: 4, name: "4팀" },
      { id: 5, name: "5팀" },
      { id: 6, name: "6팀" },
      { id: 7, name: "7팀" },
      { id: 8, name: "8팀" },
      { id: 9, name: "9팀" },
      { id: 10, name: "10팀~" },
      { id: 15, name: "15팀~" },
    ],
    defaultSelectedIds: [1, 2],
    // multipleValue: true,
    multiLineColumnCount: 5,
  });

  const { RadioBoxGroup: MonthRadioBoxGroup, activeIds: selectedMonthIds } = useRadioBoxGroup({
    items: [
      { id: 1, name: "1월" },
      { id: 2, name: "2월" },
      { id: 3, name: "3월" },
      { id: 4, name: "4월" },
      { id: 5, name: "5월" },
      { id: 6, name: "6월" },
      { id: 7, name: "7월" },
      { id: 8, name: "8월" },
      { id: 9, name: "9월" },
      { id: 10, name: "10월" },
      { id: 11, name: "11월" },
      { id: 12, name: "12월" },
    ],
    multipleValue: true,
    multiLineColumnCount: 6,
  });
  const { RadioBoxGroup: WeekRadioGroupFirst, activeIds: selectedWeekIdsFirst } = useRadioBoxGroup({
    items: [
      { id: 1, name: "1주" },
      { id: 2, name: "2주" },
      { id: 3, name: "3주" },
      { id: 4, name: "4주" },
    ],
    // multipleValue: true,
    // multiLineColumnCount: 6,
  });
  const { RadioBoxGroup: WeekRadioGroupSecond, activeIds: selectedWeekIdsSecond } =
    useRadioBoxGroup({
      items: [
        { id: 1, name: "1주" },
        { id: 2, name: "2주" },
        { id: 3, name: "3주" },
        { id: 4, name: "4주" },
      ],
    });
  const { RadioBoxGroup: DayRadioGroupFirst, activeIds: selectedDayIdsFirst } = useRadioBoxGroup({
    items: days,
  });
  const { RadioBoxGroup: DayRadioGroupSecond, activeIds: selectedDayIdsSecond } = useRadioBoxGroup({
    items: days,
  });

  const {
    PopupSelect: TimePopupSelectFirst,
    activeIds: activeTimeIdsFirst,
    propsToInject: propsToInjectFirst,
  } = usePopupSelectV2({
    defaultSelectIds: [1],
  });
  const {
    PopupSelect: TimePopupSelectSecond,
    activeIds: activeTimeIdsSecond,
    propsToInject: propsToInjectSecond,
  } = usePopupSelectV2({
    defaultSelectIds: [1],
  });

  const { RadioBoxGroup: PlayTypeBoxGroup, activeIds: activePlayTypeIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "신페리오" },
      { id: 2, name: "스트로크" },
      { id: 3, name: "매치플레이" },
    ],
  });
  const [isMealActive, setIsMealActive] = useState(false);
  const { RadioBoxGroup: PricePerPersonBoxGroup, activeIds: activePricePerPersonId } =
    useRadioBoxGroup({
      boxType: "round",
      items: [
        { id: 1, name: "1만원" },
        { id: 2, name: "2만원" },
        { id: 3, name: "3만원" },
        { id: 4, name: "4만원" },
        { id: 5, name: "5만원+" },
      ],
    });

  const { RadioBoxGroup: TeeTypeBoxGroup, activeIds: activeTeeTypeIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "블루티" },
      { id: 2, name: "화이트티" },
      { id: 3, name: "레드티" },
      { id: 4, name: "실버티" },
    ],
  });
  const { RadioBoxGroup: EtcRequirementsBoxGroup, activeIds: activeEtcRequirementsIds } =
    useRadioBoxGroup({
      multipleValue: true,
      boxType: "round",
      items: [
        { id: 1, name: "스코어카드" },
        { id: 2, name: "기념촬영" },
        { id: 3, name: "현수막" },
      ],
    });

  const { RadioBoxGroup: AwardsBoxGroup, activeIds: activeAwardsIds } = useRadioBoxGroup({
    multipleValue: true,
    boxType: "round",
    multiLineColumnCount: 4,
    // fontSize: "12px",
    items: [
      { id: 1, name: "우승" },
      { id: 2, name: "준우승" },
      { id: 3, name: "3위" },
      { id: 4, name: "행운상" },
      { id: 5, name: "특별상" },
      { id: 6, name: "메달리스트" },
      { id: 7, name: "롱게스트" },
      { id: 8, name: "니어리스트" },
      { id: 9, name: "매너상" },
      { id: 10, name: "베스트드레서", fontSize: "12px" },
    ],
  });

  const { RadioBoxGroup: MealActiveBoxGroup, activeIds: mealActiveIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "유" },
      { id: 2, name: "무" },
    ],
  });

  const { RadioBoxGroup: MealTypeBoxGroup, activeIds: mealTypeActiveIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "조식" },
      { id: 2, name: "중식" },
      { id: 3, name: "석식" },
    ],
  });

  const { RadioBoxGroup: RoomActiveBoxGroup, activeIds: roomActiveIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "유" },
      { id: 2, name: "무" },
    ],
  });

  const { RadioBoxGroup: RoomTypeBoxGroup, activeIds: roomTypeActiveIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "라운드 전" },
      { id: 2, name: "라운드 후" },
    ],
  });

  const { RadioBoxGroup: ShopActiveBoxGroup, activeIds: shopActiveIds } = useRadioBoxGroup({
    boxType: "round",
    items: [
      { id: 1, name: "유" },
      { id: 2, name: "무" },
    ],
  });

  const { value: shopItemValue, onChange: handleShopItemValueChange } = Input.hooks.useInput("");

  const { RadioBoxGroup: PayTypeBoxGroup, activeIds: activePayTypeIds } = useRadioBoxGroup({
    boxType: "round",
    // checkBoxType: true,
    items: [
      { id: 1, name: "전체 결제" },
      { id: 2, name: "팀별 결제" },
    ],
  });

  const [_1, setBookingApplication] = useRecoilState(recoilStates.bookingApplication);

  const alert = useAlert();

  const { value: additionalCommentValue, onChange: onAdditionalCommentChange } =
    Input.hooks.useInput();
  // const navigate = useNavigate()
  const [currentClubPreferenceNumber, setCurrentClubPreferenceNumber] = useState(0);

  const submitData = {
    // user: user,
    clubs: selectedClubs?.map((c) => {
      return { id: c.id, preference: c.preference };
    }),
    teamCount: selectedTeamCountIds?.[0],
    monthIds: selectedMonthIds,
    dateFirst: {
      weeks: selectedWeekIdsFirst,
      day: selectedDayIdsFirst?.map((dayId) => days.find((d) => d.id === dayId)?.name) ?? [],
      time:
        activeTimeIdsFirst?.map((tid) => times.find((t) => t.id === tid)?.str ?? null)?.[0] ?? null,
    },
    dateSecond: {
      weeks: selectedWeekIdsSecond,
      day: selectedDayIdsSecond?.map((dayId) => days.find((d) => d.id === dayId)?.name) ?? [],
      time:
        activeTimeIdsSecond?.map((tid) => times.find((t) => t.id === tid)?.str ?? null)?.[0] ??
        null,
    },
    pricePerPerson: activePricePerPersonId?.[0], //
    playTypeId: activePlayTypeIds?.[0],
    teeTypeId: activeTeeTypeIds?.[0],
    etcRequirementIds: activeEtcRequirementsIds,
    awardsIds: activeAwardsIds?.length ? activeAwardsIds : null,
    mealId: mealActiveIds?.[0] === 1 ? mealTypeActiveIds?.[0] : null,
    roomId: roomActiveIds?.[0] === 1 ? roomTypeActiveIds?.[0] : null,
    shopItem: shopActiveIds?.[0] === 1 ? shopItemValue : null,
    paymentTypeId: activePayTypeIds?.[0],
    additionalComment: additionalCommentValue,
  };

  const checkSubmitDataObj: CheckSubmitType[] = [
    {
      idToFocus: "club-section",
      name: "희망골프장",
      checkCondition: Boolean(submitData?.clubs?.length && submitData?.clubs?.length > 1),
      additionalMsg: "최소 2개 골프장을 선택해야합니다.",
    },
    {
      idToFocus: "team-section",
      name: "신청 팀수",
      checkCondition: Boolean(submitData?.teamCount),
    },
    {
      idToFocus: "month-section",
      name: "신청 월",
      checkCondition: Boolean(submitData?.monthIds?.length),
    },
    {
      idToFocus: "date-one",
      name: "신청일자 - 1지망",
      checkCondition:
        Boolean(submitData?.dateFirst?.day?.length) &&
        Boolean(submitData?.dateFirst?.weeks?.length),
    },
    {
      idToFocus: "date-two",
      name: "신청일자 - 2지망",
      checkCondition:
        Boolean(submitData?.dateSecond?.day?.length) &&
        Boolean(submitData?.dateSecond?.weeks?.length),
    },
  ];

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    try {
      const res = await createTeeshotAxiosInstance().post("/yearBooking/booking", {
        submitData: { ...submitData, customerSignBase64: signBase64 },
      });

      if (res.data.success === false) {
        throw new Error();
      }
      const fetchedData = res.data?.data;
      if (fetchedData) {
        setIsSubmitLoading(false);

        alert({
          title: "연단체 신청완료",
          content: (
            <div className="d-flex fd-c gap-2">
              <p>연간 부킹신청이 완료되었습니다.</p>
              <p>
                * 신청하신 조건을 골프장에서 검토한 후에 “승낙 / 변경조건 / 거절”을 앱내
                통지드립니다.
              </p>
            </div>
          ),
          onClose: () => {
            alert.resume();
          },
        });
        await alert.pause();
        setBookingApplication(fetchedData);
      } else {
        throw new Error("데이터 없음");
      }
    } catch (e) {
      console.log("e :", e);

      alert({
        content: (
          <>
            <div>전송 실패</div>
          </>
        ),
        onClose: () => {
          alert.resume();
        },
      });
      await alert.pause();
    } finally {
      setIsSubmitLoading(false);

      navigate("/year-booking");
    }
  };

  return (
    <>
      <Container className="flex-1 d-flex fd-c">
        <div className="flex-1 d-flex fd-c gap-4">
          <p>희망하시는 연간 부킹 신청 정보를 입력해 주세요.</p>
          {/* <GroupLocationPopupSelect /> */}
          <div className="d-flex fd-c gap-3">
            <Label className="bold" content="골프장_ 3개 선택 가능" isEssential={true} />
            <FindInputButton
              label="1"
              tabIndex={1}
              id={"club-section"}
              required={true}
              selectedName={selectedClubs?.filter((c) => c.preference === 1)[0]?.name ?? ""}
              onClick={() => {
                setCurrentClubPreferenceNumber(1);
                openClubSearchPopup();
              }}
            />
            <FindInputButton
              label="2"
              // tabIndex={2}
              required={true}
              selectedName={selectedClubs?.filter((c) => c.preference === 2)[0]?.name ?? ""}
              onClick={() => {
                if (!(selectedClubs && selectedClubs.some((club) => club.preference === 1))) {
                  alert({ content: "1지망 골프장을 선택하세요." });
                  return;
                }
                setCurrentClubPreferenceNumber(2);
                openClubSearchPopup();
              }}
            />
            <FindInputButton
              label="3"
              // tabIndex={3}
              required={false}
              selectedName={selectedClubs?.filter((c) => c.preference === 3)[0]?.name ?? ""}
              onClick={() => {
                if (!(selectedClubs && selectedClubs.some((club) => club.preference === 2))) {
                  alert({ content: "1, 2지망 골프장을 선택하세요." });

                  return;
                }
                setCurrentClubPreferenceNumber(3);
                openClubSearchPopup();
              }}
            />
          </div>
          <div className="d-flex fd-c gap-1">
            <Label isEssential={true} content="신청 팀수" className="bold" />
            <div id={"team-section"} className="focus-outline" tabIndex={2}>
              <TeamRadioBoxGroup />
            </div>
          </div>

          <div className="d-flex fd-c gap-1">
            <Label isEssential={true} content="신청 월" className="bold" />
            <div id={"month-section"} className="focus-outline" tabIndex={3}>
              <MonthRadioBoxGroup />
            </div>
          </div>

          <div className="d-flex fd-c gap-1">
            <Label isEssential={true} content="신청일자" className="bold" />
            <div>
              <ContentBox
                tabIndex={4}
                className="focus-outline"
                id={"date-one"}
                content={
                  <div className="d-flex fd-c gap-1">
                    <Label className="as-s bold" isEssential={true} content="1지망" />
                    <div className="d-flex fd-c gap-s">
                      <WeekRadioGroupFirst />
                      <DayRadioGroupFirst />
                    </div>
                    <TimePopupSelectFirst
                      {...propsToInjectFirst}
                      items={times}
                      label={"시작시간"}
                      boldLabel={true}
                      isEssential={true}
                    />
                  </div>
                }
              />
            </div>
            <div>
              <ContentBox
                tabIndex={4}
                className="focus-outline"
                id={"date-two"}
                content={
                  <div className="d-flex fd-c gap-1">
                    <Label className="as-s bold" isEssential={true} content="2지망" />
                    <div className="d-flex fd-c gap-s">
                      <WeekRadioGroupSecond />
                      <DayRadioGroupSecond />
                    </div>
                    <TimePopupSelectSecond
                      {...propsToInjectSecond}
                      items={times}
                      label={"시작시간"}
                      boldLabel={true}
                      isEssential={true}
                    />
                  </div>
                }
              />
            </div>
          </div>

          <div className="d-flex fd-c gap-1">
            <Label content="경기방식" className="bold" />
            <PlayTypeBoxGroup />
          </div>
          <div className="d-flex fd-c gap-1">
            <Label content="티사용" className="bold" />
            <TeeTypeBoxGroup />
          </div>
          <div className="d-flex fd-c gap-1">
            <Label content="요청사항" className="bold" />
            <EtcRequirementsBoxGroup />
          </div>
          <div>
            <div className="d-flex ai-c mb-1 gap-1">
              <Label content="1인 객단가" className="bold" />
              <p className="fs-2">(* 연단체 우선순위 배정의 기준)</p>
            </div>
            <PricePerPersonBoxGroup />
          </div>
          <div className="d-flex fd-c gap-1">
            <Label content="시상요청" className="bold" />
            <AwardsBoxGroup />
          </div>
          <div className="d-flex fd-c gap-1">
            <Label content="식사" className="bold" />
            <div className="d-flex ">
              <div className="flex-1">
                <MealActiveBoxGroup />
              </div>
              <div className="flex-1" />
            </div>
            {mealActiveIds?.[0] === 1 && <MealTypeBoxGroup />}
          </div>
          <div className="d-flex fd-c gap-1">
            <Label content="룸이용" className="bold" />
            <div className="d-flex ">
              <div className="flex-1">
                <RoomActiveBoxGroup />
              </div>
              <div className="flex-1" />
            </div>
            {roomActiveIds?.[0] === 1 && <RoomTypeBoxGroup />}
          </div>
          <div className="d-flex fd-c gap-1">
            <Label content="프로샵 이용" className="bold" />
            <div className="d-flex ">
              <div className="flex-1">
                <ShopActiveBoxGroup />
              </div>
              <div className="flex-1" />
            </div>
            {shopActiveIds?.[0] === 1 && (
              <div className="ml-5">
                <Input
                  label={"시상품"}
                  attr={{ value: shopItemValue, onChange: handleShopItemValueChange }}
                />
              </div>
            )}
          </div>

          <div className="d-flex fd-c gap-1">
            <Label content="결제" className="bold" />
            <PayTypeBoxGroup />
          </div>
          <div className="mb-3">
            <Label content="기타사항" className="bold" />
            <Input attr={{ value: additionalCommentValue, onChange: onAdditionalCommentChange }} />
          </div>
          <div className="bottom-comment d-flex fd-c jc-c">
            <p>* 신청하신 조건을 골프장에서 검토한 후에</p>
            <p>
              <span className="fc-red bold">승낙 / 변경조건 / 거절</span> 을 앱내 통지드립니다.
            </p>
          </div>
        </div>

        <DoubleButton
          nagativeMargin
          left={{
            name: "취소",
            themeColor: "lightGray",
            onClick: () => {
              navigate("/booking-register");
            },
          }}
          right={(() => {
            return {
              name: "신청",
              themeColor: "green",
              onClick: () => {
                const check = checkSubmitData(checkSubmitDataObj);
                if (!check.isOk) {
                  alert({
                    content: (
                      <div className="d-flex fd-c gap-2">
                        <p>
                          [ <span className="bold">{check.msg}</span> ] 을(를) 입력해주세요.
                        </p>
                        {check.additionalMsg ? <>{check.additionalMsg}</> : null}
                      </div>
                    ),
                    onClose: check.focusTarget,
                  });
                  return;
                }

                setIsSignModalOpened(true);
              },
            };
          })()}
        />
      </Container>
      {isSignModalOpened && (
        <>
          <Modal
            title="신청인 서명"
            setModalState={setIsSignModalOpened}
            onConfirm={() => {
              handleSubmit();
            }}
            rejectConfirmCondition={Boolean(!signBase64)}
            rejectConfirmMsg="먼저 서명을 진행하세요."
            content={
              <>
                <p>신청인: {user.realname}</p>
                <SignModalContent setSignBase64={setSignBase64} />
              </>
            }
          />
        </>
      )}
      <ClubFindPopupComp
        type={"back"}
        headerMsg={"검색"}
        content={
          <>
            <ClubFindPopupContents
              setAnnouncementShowingClub={setAnnouncementShowingClub}
              openAnnounceAgree={openAnnouncePopup}
              groupLocations={groupLocations}
            />
          </>
        }
      />
      <AnnouncePopupComp
        headerMsg={`${announcementShowingClub?.name} 모집공고`}
        type={"back"}
        content={
          <>
            <>
              <AnnouncementAgreePopupContents
                closeMe={closeAnnounce}
                closeParent={closeClubSearchPopup}
                announcementShowingClub={announcementShowingClub}
                preference={currentClubPreferenceNumber}
                setSelectedClubs={setSelectedClubs}
              />
            </>
          </>
        }
      />
      {isSubmitLoading && <SreenCoverLoading />}
      {isGroupDataLoading && <SreenCoverLoading />}
    </>
  );
}

const Container = styled.div`
  .bottom-comment {
    background-color: ${colors.gray.fourth};
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -20px;
    padding: 15px;
  }
`;
