import React, { ReactNode } from "react";
import { FaPeopleGroup, FaRegHandshake, FaHandshake } from "react-icons/fa6";
import { GiTabletopPlayers, GiTalk } from "react-icons/gi";
import { PiHandDepositBold, PiHandDepositLight } from "react-icons/pi";
import { GrGroup } from "react-icons/gr";
import { BiDollar } from "react-icons/bi";
import { MdOutlineContactMail } from "react-icons/md";
import { HiChatBubbleLeftRight } from "react-icons/hi2";
import { IoDocumentTextOutline, IoDocumentText } from "react-icons/io5";
import { colors } from "src/styles/theme";
import styled from "styled-components";

type IconName =
  | "group-register"
  | "booking-register"
  | "select-club"
  | "confirm-club"
  | "money"
  | "invite-member";

// 단체등록      <FaPeopleGroup /> <GrGroup />
// 부킹신청     <IoDocumentTextOutline /> <IoDocumentText /> <PiHandDepositBold /> <PiHandDepositLight />
// 조건협의    <GiTabletopPlayers /> <GiTalk /> <HiChatBubbleLeftRight />
// 부킹확정    <FaRegHandshake /> <FaHandshake />
// 보증금확인  <BiDollar />
// 멤버등록    <MdOutlineContactMail />

const IconCompObj: { [key in IconName]: ReactNode } = {
  "group-register": <FaPeopleGroup />,
  "booking-register": <IoDocumentText />,
  "select-club": <HiChatBubbleLeftRight />,
  "confirm-club": <FaHandshake />,
  money: <BiDollar />,
  "invite-member": <MdOutlineContactMail />,
};

function Stepper(props: {
  className?: string;
  stepsXpadding?: string;
  steps: {
    label?: ReactNode;
    nextLineType?: "dotted" | "solid";
    title?: string;
    isError?: boolean;
    icon?: IconName;
  }[];
  currentIdx?: number | null;
  currentStatus?: "done" | "error";
  title?: string;
}) {
  return (
    <Container className={props.className} $stepsXpadding={props.stepsXpadding}>
      {props.title ? <h4>{props.title}</h4> : null}
      <div className="steps">
        {props.steps.map((st, idx) => {
          return (
            <Step
              key={`step-${idx}`}
              className={(() => {
                const classes = [];
                if (props.currentIdx === null) {
                  classes.push("inactive");
                }
                if (props.steps.length - 1 !== idx) {
                  classes.push("flex-1");
                }
                if (props.currentIdx === idx && !st.isError) {
                  classes.push("dotted");
                }
                if ((props.currentIdx ?? 9999) < idx) {
                  classes.push("inactive");
                }
                if (st.isError) {
                  classes.push("error");
                }
                return classes.join(" ");
              })()}
            >
              <div className="btn">
                <div className="icon-box">{st.icon ? IconCompObj[st.icon] : ""}</div>
                <div className={`label-section`} key={`label-${idx}`}>
                  {st.label}
                </div>
              </div>
              {props.steps.length - 1 === idx ? null : (
                <>
                  <div className="line" />
                </>
              )}
            </Step>
          );
        })}
      </div>
    </Container>
  );
}

export default Stepper;

const Container = styled.div<{ $stepsXpadding?: string }>`
  padding: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  justify-content: space-between;
  padding-bottom: 50px;
  color: white;
  h4 {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    padding: 5px;
  }
  .steps {
    padding: 0 ${(p) => (p.$stepsXpadding ? p.$stepsXpadding : "auto")};
    display: flex;
  }
  background-color: #44546a;
`;

const Step = styled.div`
  display: flex;
  align-items: center;

  &.inactive {
    .btn {
      background-color: white;
      .icon-box {
        color: #898989;
      }
    }
    .line {
      border-bottom-color: ${colors.gray.first};
    }
  }
  &.dotted {
    .line {
      border-bottom-style: dotted;
    }
  }
  &.error {
    .btn {
      background-color: red !important;
    }
    .line {
      border-bottom-color: ${colors.gray.first};
    }
  }
  .btn {
    background-color: ${colors.green.second};
    width: 25px;
    height: 25px;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-box {
      color: black;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .label-section {
      font-size: 12px;
      display: flex;
      justify-content: center;
      width: 60px;
      position: absolute;
      left: 50%;
      bottom: -10px;
      transform: translate(-50%, 100%);
    }
  }

  .line {
    flex: 1;
    border-bottom-width: 4px;
    border-bottom-color: ${colors.green.second};
    border-bottom-style: solid;
  }
`;

const Line = styled.div``;
