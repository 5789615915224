import Lottie from "lottie-react";
import React, { ComponentProps, useEffect, useState } from "react";

export default function Animation(
  props: Omit<ComponentProps<typeof Lottie>, "animationData"> & { jsonFetchPath: string }
) {
  const [animationData, setAnimationData] = useState<any>(null);
  const { jsonFetchPath, ...propsToGive } = props;

  useEffect(() => {
    fetch(jsonFetchPath)
      .then((response) => response.json())
      .then((data) => setAnimationData(data))
      .catch((error) => console.error("Error loading animation:", error));
  }, []);

  return <Lottie animationData={animationData} {...propsToGive} />;
}
