import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaArrowLeft } from "react-icons/fa6";
import { BsPatchQuestion } from "react-icons/bs";
import { colors } from "src/styles/theme";
import Button from "../Button";
import { useAlert } from "src/hooks";

export default function MainLayout() {
  return (
    <Contianer>
      <Header />
      <OutletCover className="page-default">
        <Outlet />
      </OutletCover>
    </Contianer>
  );
}
const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const alert = useAlert();
  
  return (
    <StyledHeader className="header-height">
      {pathname == "/year-booking" ? (
        <Button
          key={window.location.href + window.location.search}
          id={"close-button"}
          // close-button => app에서 주입받는 script를 위한 id
          $colorTheme="white"
        >
          <FaArrowLeft size={"20px"} />
        </Button>
      ) : (
        <Button
          key={window.location.href + window.location.search}
          id={`${pathname}-${Date.now()}`}
          $colorTheme="white"
          onClick={() => {
            navigate(-1);
          }}
        >
          <FaArrowLeft size={"20px"} />
        </Button>
      )}
      <h2>연단체 부킹</h2>
      <Button $colorTheme="white" onClick={() => alert("문의-티샷: 02-2277-3489)")}>
        <BsPatchQuestion size={"20px"} />
      </Button>
    </StyledHeader>
  );
};
const OutletCover = styled.div`
  overflow: auto;
`;

const Contianer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
`;
const StyledHeader = styled.header`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${colors.gray.second};
`;

const Body = styled.div`
  flex: 1;
`;
