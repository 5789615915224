import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Divider } from "src/components";
import { useAlert } from "src/hooks";
import styled from "styled-components";

const arr = new Array(30).fill(1);
export default function My() {
  const [count, setCount] = useState(1);
  const fn1 = () => {
    return 33;
  };
  // const handleChange = useCallback((newState: any) => {
  //   setCount(newState);
  // }, []);

  const zzzz = useMemo(() => 1, []);

  const alert = useAlert();

  return (
    <Container className="ffff">
      <hr />
      accept="image/png, image/gif, image/jpeg{" "}
      <input type="file" accept="image/png, image/gif, image/jpeg" />
      <hr />
      image/png,image/jpeg
      <input type="file" accept="image/png,image/jpeg" />
      <hr />
      image/*
      <input type="file" accept="image/*" />
      <hr />
      capture u accept="image/png, image/gif, image/jpeg{" "}
      <input type="file" accept="image/png, image/gif, image/jpeg" capture="user" />
      <hr />
      capture u image/png,image/jpeg
      <input type="file" accept="image/png,image/jpeg" capture="user" />
      <hr />
      capture u image/*
      <input type="file" accept="image/*" capture="user" />
      <hr />
      capture e accept="image/png, image/gif, image/jpeg{" "}
      <input type="file" accept="image/png, image/gif, image/jpeg" capture={"environment"} />
      <hr />
      capture e image/png,image/jpeg
      <input type="file" accept="image/png,image/jpeg" capture={"environment" as any} />
      <hr />
      capture e image/*
      <input type="file" accept="image/*" capture={"environment" as any} />
      <hr />
      <div>
        <button
          onClick={() => {
            (async () => {
              alert({ content: "alert1", onClose: () => alert.resume() });
              await alert.pause();
            })();
          }}
        >
          alert 1
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            (async () => {
              alert({ content: "alert1", onClose: () => alert.resume() });
              await alert.pause();
            })();
          }}
        >
          alert 2
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            (async () => {
              alert({ content: "alert2", onClose: () => alert.resume() });
              await alert.pause();
            })();
          }}
        >
          alert 3
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            (async () => {
              alert({ content: "alert3", onClose: () => alert.resume() });
              await alert.pause();
            })();
          }}
        >
          alert 4
        </button>
      </div>
      <div>{count}</div>
      <button
        onClick={() => {
          setCount((c) => c + 1);
        }}
      >
        d
      </button>
      {/* <div className="inner">
        {arr.map((x) => {
          return <div className="cc">{x}</div>;
        })}
      </div> */}
      <Chil zz={fn1} />
    </Container>
  );
}

const Container = styled.div`
  /* flex: 1;
  min-height: 0;
  height: 100%;

  overflow: hidden;

  .inner {
    height: 100%;
    overflow: auto;
    background-color: coral;
    .cc {
      margin: 5px;
      background: #000;
    }
  } */
`;

const Chil = (props: { zz: any }) => {
  // console.log(props.zz);

  useEffect(() => {
    // console.log(" 나: load");
  }, []);

  return <div>나나</div>;
};
