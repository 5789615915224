import { useEffect, useState } from "react";
import Button from "../Button";
// import { usePagePopup } from "../PopupPage";
import styled from "styled-components";
import ContentBox from "../ContentBox";
import { useNavigate } from "react-router-dom";
import usePopupPage from "../PopupPage";
import Stepper from "../Stepper";
import Animation from "../Animation";
import { colors } from "src/styles/theme";
import { recoilStates } from "src/recoil/recoilStates";
import { useRecoilState } from "recoil";
import { IoMailUnreadOutline } from "react-icons/io5";
export default function HasProposal() {
  const [bookingApplication] = useRecoilState(recoilStates.bookingApplication);

  // 신청 직후 1, 조건제시 3, 클럽거절7
  const acceptedClubs = bookingApplication?.clubRels?.filter((c) =>
    [3].includes(c?.year_booking_status_id)
  );

  const navigate = useNavigate();

  return (
    <>
      <Container className="d-flex fd-c gap-2">
        <Stepper
          className="page-pd-negative-side page-pd-negative-top"
          currentIdx={2}
          title="조건 검토 후 확정하세요~"
          steps={[
            { label: <>단체등록</>, icon: "group-register" },
            { label: "부킹 신청", icon: "booking-register" },
            { label: "조건 협의", icon: "select-club" },
            { label: "부킹 확정", icon: "confirm-club" },
            // {
            //   label: (
            //     <div className="d-flex fd-c ai-c">
            //       <div>보증금</div>
            //       <div>확인</div>
            //     </div>
            //   ),
            //   icon: "money",
            // },
            // { label: "멤버 등록", icon: "invite-member" },
          ]}
        />
        <div className="ani-box d-flex jc-c ai-c">
          <Animation jsonFetchPath={`${process.env.PUBLIC_URL}/selection.json`} loop={false} />
        </div>
        <ContentBox
          className=""
          colorTheme="gray"
          onClick={() => {
            navigate("/year-booking/proposals");
            // openBookingProposalPagePopup();
          }}
          content={
            <div className="d-flex fd-c gap-2 jc-c ai-c pt-2">
              {acceptedClubs?.map((c) => {
                return (
                  <div key={`accept-msg-${bookingApplication?.id}-${c.club_id}`} className="">
                    <p className="fs-4 d-flex ai-c gap-1">
                      <IoMailUnreadOutline fontSize={20} className="mr-x" />{" "}
                      <span className="bold"> {c.name}</span> 부킹조건 도착!
                    </p>
                  </div>
                );
              })}
              {/* <p className="date">접수일시: {bookingApplication?.created_at}</p> */}
              <div className="as-str d-flex fd-c mt-4 as-str">
                <Button $colorTheme="green" className="bold" $buttonSize="md" $round>
                  보러가기
                </Button>
              </div>
            </div>
          }
        ></ContentBox>
      </Container>
    </>
  );
}

const Container = styled.div`
  .ani-box {
    > div {
      max-width: 250px;
    }
    min-width: 1px;
  }
`;

const ReqContent = styled.div`
  overflow: auto;
  .img-box {
    padding: 5px;
    img {
      width: 100%;
    }
  }
`;
