import React, { ReactNode, useEffect } from "react";
import { recoilStates } from "./recoilStates";
import { useRecoilState } from "recoil";
import { sleep } from "src/utils";
import { createTeeshotAxiosInstance } from "src/http";
import { useAlert } from "src/hooks";

export default function InitRecoil() {
  const [group, setGroup] = useRecoilState(recoilStates.group);
  const [_2, setInit] = useRecoilState(recoilStates.init);
  const [user, setUser] = useRecoilState(recoilStates.user);
  const [_5, setBookingApplication] = useRecoilState(recoilStates.bookingApplication);

  const alert = useAlert();
  useEffect(() => {
    (async () => {
      if (!user.id) {
        return;
      }
      // const groupRes = await axiosTeeshotInstance.get("/yearBooking/group");

      try {
        const groupRes = await createTeeshotAxiosInstance().get("/yearBooking/group");
        if (groupRes.data.success === false) {
          throw new Error("group 조회 실패");
        }
        const groupData = groupRes?.data?.data;
        setGroup({ ...groupData.group, members: groupData.members });

        const yearBookingRes = await createTeeshotAxiosInstance().get("/yearBooking/booking");
        if (yearBookingRes?.data?.data) {
          setBookingApplication(yearBookingRes?.data?.data);
        }
        setInit({ isInit: true });
      } catch (e) {
        alert(String(e));
      }
    })();
  }, [user?.id]);

  return <></>;
}
