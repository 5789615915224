import { useEffect, useState } from "react";
import { sleep } from "src/utils";

const groupData = {
  id: 1,
  name: "그룹명1",
  location: { id: 1, name: "경기/북부" },
};
const getGroupData = async (groupId: number) => {
  // axios ...
  await sleep(500);
  return groupData;
};

function useGroupData(groupId: number) {
  const [groupData, setGroupData] = useState<{
    id: number;
    name: string;
    location: { id: number; name: string };
  }>();

  useEffect(() => {
    (async () => {
      const fetchedGroupData = await getGroupData(groupId);
      setGroupData(fetchedGroupData);
    })();
  }, []);

  return groupData;
}

export default useGroupData;
