import { AiOutlineLoading3Quarters } from "react-icons/ai";
import styled from "styled-components";

export default function Loading(props: { size?: string }) {
  return (
    <Container>
      <AiOutlineLoading3Quarters size={props.size ?? "35px"} />
    </Container>
  );
}

const Container = styled.span`
  svg {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;
