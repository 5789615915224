import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { VscTriangleDown } from "react-icons/vsc";
import Label from "./Label";
import Modal from "./Modal";
import { District } from "src/types";
import { colors } from "src/styles/theme";

export function usePopupLocation(args?: { defaultSelectIds?: number[]; items?: any }) {
  // TODO: useModal hook 으로 리팩토링
  const [isPopupShown, setIsPopupShown] = useState(false);
  const [activeParentId, setActiveParentId] = useState<number | null>(null);
  const [activeChildId, setActiveChildId] = useState<number | null>(null);
  const [activeDistrictObj, setActiveDistrictObj] = useState<{ id: number; name: string }>();

  const propsToInject = {
    activeParentId,
    activeChildId,
    setActiveParentId,
    setActiveChildId,
    isPopupShown,
    setIsPopupShown,
    activeDistrictObj,
    setActiveDistrictObj,
    defaultSelectIds: args?.defaultSelectIds,
  };

  useEffect(() => {
    const defaultId = args?.defaultSelectIds?.[0];
    if (!defaultId) {
      return;
    }
    const defaultParentId = String(defaultId).slice(0, 2);
    const defaultChildId = String(defaultId).slice(2, 5);
    if (Number(defaultParentId)) {
      setActiveParentId(Number(defaultParentId));
    }
    if (Number(defaultChildId)) {
      setActiveChildId(Number(defaultChildId));
    }
  }, [args?.defaultSelectIds?.[0]]);

  const PopupLocation = useCallback(
    (props: {
      tabIndex?: number;
      inputId?: string;
      items?: District[];
      defaultSelectIds?: number[];
      placeholder?: string;
      label?: string;
      boldLabel?: boolean;
      isEssential?: boolean;
      activeParentId: number | null;
      activeChildId: number | null;
      setActiveChildId: React.Dispatch<React.SetStateAction<number | null>>;
      setActiveParentId: React.Dispatch<React.SetStateAction<number | null>>;
      isPopupShown: boolean;
      setIsPopupShown: React.Dispatch<React.SetStateAction<boolean>>;
      activeDistrictObj:
        | {
            id: number;
            name: string;
          }
        | undefined;
      setActiveDistrictObj: React.Dispatch<
        React.SetStateAction<
          | {
              id: number;
              name: string;
            }
          | undefined
        >
      >;
    }) => {
      return (
        <>
          <Container className="" onClick={() => props.setIsPopupShown(true)}>
            {props.label && (
              <Label
                content={props.label}
                isEssential={props.isEssential}
                className={`${props.boldLabel ? "bold" : ""} mb-1`}
              />
            )}
            <div
              className="content-main input-box-type d-flex jc-sb ai-c"
              tabIndex={props.tabIndex}
              id={props.inputId || `${Date.now()}-group-lc`}
            >
              {props.activeParentId ? (
                (() => {
                  const p = props.items?.find((v) => {
                    return v.cd === props.activeParentId;
                  });
                  const c = p?.list?.find((v) => {
                    return v.cd === props.activeChildId;
                  });
                  return `${p?.name || ""} ${c?.name || ""}`;
                })()
              ) : (
                <div className="placeholder">{props.placeholder}</div>
              )}
              <VscTriangleDown />
            </div>
          </Container>
          {props.isPopupShown && (
            <Modal
              setModalState={props.setIsPopupShown}
              onConfirm={() => {
                props.setActiveDistrictObj(() => {
                  return {
                    id: Number(
                      String(props.activeParentId) +
                        String(props.activeChildId ?? 0).padStart(3, "0")
                    ),
                    name: (() => {
                      const activeParent = props.items?.find((i) => i.cd === props.activeParentId);
                      const activeChild = activeParent?.list?.find(
                        (i) => i.cd === props.activeChildId
                      );
                      return `${activeParent?.name ?? ""} ${activeChild?.name ?? ""}`;
                    })(),
                  };
                });
              }}
              title={props.label ? props.label : null}
              content={
                <ModalContentContainer>
                  <div className="columns">
                    <div className="list-container">
                      <ul className="parents">
                        {props.items?.map((parent) => {
                          return (
                            <li
                              className={`${props.activeParentId === parent.cd ? "active" : ""}`}
                              key={`${parent.name}-${parent.cd}`}
                              onClick={() => {
                                props.setActiveChildId(null);
                                props.setActiveParentId((currentId) =>
                                  currentId === parent.cd ? null : parent.cd
                                );
                              }}
                            >
                              <div>{parent.name}</div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="list-container">
                      <ul className="children">
                        {props.items
                          ?.find((v) => v.cd === props.activeParentId)
                          ?.list?.map((child) => {
                            return (
                              <li
                                onClick={() =>
                                  props.setActiveChildId((currentId) =>
                                    currentId === child.cd ? null : child.cd
                                  )
                                }
                                className={`${props.activeChildId === child.cd ? "active" : ""}`}
                                key={`${child.name}-${child.cd}`}
                              >
                                {child.name}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </ModalContentContainer>
              }
            ></Modal>
          )}
        </>
      );
    },
    [isPopupShown]
  );

  return { PopupLocation, propsToInject, activeChildId, activeParentId };
}

const Container = styled.div`
  label {
    display: inline-block;
  }
  cursor: pointer;
  .content-main {
    .placeholder {
      color: ${colors.gray.first};
      font-size: 12px;
    }
  }
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 15px; */

  h4 {
    padding: 10px;
  }
  .never {
    background-color: #979797;
  }
  .columns {
    max-height: 400px;
    overflow: auto;
    display: flex;
    gap: 20px;
    > .list-container {
      flex: 1;
    }
    > .list-container > ul {
      display: grid;
      gap: 5px;
      grid-template-columns: 1fr 1fr;
      height: auto;
      gap: 5px;
      font-weight: 700;
      font-size: 14px;
      &.parents {
        > li {
          /* background-color: #8ce1a5; */
          background-color: ${colors.gray.second};
        }
        > li.active {
          background-color: #0b7b4b;
          color: white;
        }
      }
      &.children {
        li {
          background-color: #cdd2ff;
          background-color: ${colors.gray.second};
        }
        > li.active {
          background-color: ${colors.purple.first};
          color: white;
        }
      }
      > li {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
      }
    }
  }
`;
