import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Input, Label, SreenCoverLoading, Modal } from "src/components";
// TODO: 모달 컴포넌트 변경
import { useAlert, useGroupPosition } from "src/hooks";
import { createTeeshotAxiosInstance } from "src/http";
import { recoilStates } from "src/recoil/recoilStates";
import { colors } from "src/styles/theme";
import styled from "styled-components";
import { MemberType } from "src/types";
import { usePopupSelectV2 } from "src/components/PopupSelect";
import { sleep } from "src/utils";
function YearGroupMembersList() {
  const [group, setGroup] = useRecoilState(recoilStates.group);
  const [isLoading, setIsLoading] = useState(true);
  const positions = useGroupPosition();
  const {
    value: nameValue,
    onChange: handleNameChange,
    resetValue: resetName,
  } = Input.hooks.useInput();
  const {
    value: contactValue,
    onChange: handleContactChange,
    resetValue: resetContact,
  } = Input.hooks.useInput("");
  // TODO 폰 번호형식 유효성검사
  const {
    PopupSelect,
    activeIds: activebooking_group_member_level_ids,
    reset: resetLevels,
    propsToInject,
  } = usePopupSelectV2();

  const [isMemberEditModalShown, setIsMemberEditModalShown] = useState(false);
  const [editTargetMember, setEditTargetMember] = useState<MemberType | null>(null);

  const {
    PopupSelect: EditPopupSelect,
    activeIds: activeEditingMemberLevelIds,
    propsToInject: editPropsToInject,
  } = usePopupSelectV2({
    defaultSelectIds: editTargetMember ? [editTargetMember.booking_group_member_level_id] : [2],
  });

  const { value: editingMemberNameValue, onChange: handleChangeEditingMemberNameValue } =
    Input.hooks.useInput();
  const { value: editingMemberContactValue, onChange: handleChangeEditingMemberContactValue } =
    Input.hooks.useInput();

  useEffect(() => {
    // TODO Input 컴포넌트 수정 후 리팩토링
    handleChangeEditingMemberNameValue({
      target: { value: editTargetMember?.name },
    } as any);
    handleChangeEditingMemberContactValue({
      target: { value: editTargetMember?.phone_number },
    } as any);
  }, [editTargetMember]);

  useEffect(() => {
    if (positions) {
      setIsLoading(false);
    }
  }, [positions]);
  const alert = useAlert();
  const handleBtnClick = () => {
    if (!nameValue || !contactValue) {
      alert("이름과 연락처를 입력하세요.");
      return;
    }
    (async () => {
      setIsLoading(true);
      const submitData = {
        groupId: group.id,
        name: nameValue,
        contact: contactValue,
        levelId: activebooking_group_member_level_ids?.[0] ?? 3,
      };
      try {
        const res = await createTeeshotAxiosInstance().post("/yearBooking/member", {
          submitData,
        });
        if (!res?.data?.data || res.data.success === false) {
          throw new Error("오류");
        }
        setIsLoading(false);
        resetName();
        resetContact();
        resetLevels();
        setGroup({
          ...group,
          members: [...group.members, res.data.data],
        });
      } catch (e) {
        alert({ content: "오류발생", onClose: () => alert.resume() });
        await alert.pause();
        window.location.reload();
        console.log(" e:", e);
      }
    })();
  };

  return (
    <>
      {isMemberEditModalShown && (
        <Modal
          setModalState={setIsMemberEditModalShown}
          onConfirm={() => {
            const submitData = {
              id: editTargetMember?.id,
              name: editingMemberNameValue.trim(),
              contact: editingMemberContactValue.trim(),
              levelId: activeEditingMemberLevelIds?.[0] ?? 3,
            };
            if (!submitData?.id || !submitData?.name || !submitData?.contact) {
              alert("필수 입력데이터 없음");
              return;
            }
            (async () => {
              setIsLoading(true);
              try {
                const res = await createTeeshotAxiosInstance().put("/yearBooking/member", {
                  submitData,
                });
                if (!res.data.success) {
                  throw new Error();
                }
                setGroup((prev) => {
                  return { ...prev, members: res.data?.data?.members };
                });
              } catch (e) {
                alert({ content: "멤버 정보 수정 실패", onClose: () => alert.resume() });
                await alert.pause();
                window.location.reload();
                console.log("e :", e);
              } finally {
                setIsLoading(false);
              }
            })();
          }}
          content={
            <>
              <div className="flex-1 page-pd d-flex fd-c gap-2">
                <div>
                  <EditPopupSelect
                    {...editPropsToInject}
                    items={positions ?? []}
                    label="직책"
                    placeholder="직책"
                  />
                </div>
                <div>
                  <Input
                    label="이름"
                    attr={{
                      value: editingMemberNameValue,
                      onChange: handleChangeEditingMemberNameValue,
                      placeholder: "예시) 홍길동",
                    }}
                  />
                </div>
                <div>
                  <Input
                    label="전화번호"
                    attr={{
                      type: "number",
                      value: editingMemberContactValue,
                      onChange: handleChangeEditingMemberContactValue,
                      placeholder: "예시) 01012345678",
                    }}
                  />
                </div>
              </div>
            </>
          }
        />
      )}
      <Container className="flex-1 d-flex fd-c gap-2 jc-sb">
        <div className="d-flex fd-c gap-1">
          {group.members?.length > 0 ? (
            <>
              <div>멤버수: {group.members?.length} 명</div>
              <div className="list-table">
                <div className="t-header ">
                  <div className="item ">직책</div>
                  <div className="item">이름</div>
                  <div className="item">연락처</div>
                  <div className="item fs-2">티샷가입</div>
                  <div className="item">수정</div>
                </div>
                <ul className="member-list">
                  {group.members.map((m) => {
                    return (
                      <li key={m.id} onClick={() => {}}>
                        <div className="item">
                          {positions?.find((p) => p.id === m.booking_group_member_level_id)?.name}
                        </div>
                        <div className="item">{m.name}</div>
                        <div className="item">{m.phone_number}</div>
                        <div className="item">{m.teeshot_user_id ? "o" : ""}</div>
                        <div className="item">
                          <div>
                            <Button
                              $colorTheme="green"
                              $buttonSize="xs"
                              onClick={() => {
                                setEditTargetMember(m);
                                setTimeout(() => {
                                  setIsMemberEditModalShown(true);
                                }, 0);
                              }}
                            >
                              수정
                            </Button>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          ) : (
            <p>아직 멤버가 없습니다.</p>
          )}
        </div>

        <div className="d-flex fd-c gap-2 mt-2">
          <Label className="bold" content="멤버 추가하기" />
          {/* TODO: 회원등록 폼 화면위로 띄우기*/}
          <div className="d-flex gap-1">
            <div>
              <PopupSelect
                {...propsToInject}
                items={positions ?? []}
                placeholder="직책"
                label="직책"
              />
            </div>
            <div>
              <div className="mb-1">이름</div>
              <Input
                attr={{
                  style: { width: "100px" },
                  value: nameValue,
                  onChange: handleNameChange,
                  placeholder: "예시) 홍길동",
                }}
              />
            </div>
            <div className="flex-1">
              <div className="mb-1">연락처</div>
              <Input
                attr={{
                  type: "number",
                  style: { width: "100%" },
                  value: contactValue,
                  onChange: handleContactChange,
                  placeholder: "예시) 01012345678",
                }}
              />
            </div>
          </div>
          <Button
            $colorTheme="green"
            $buttonSize="md"
            $round
            onClick={() => {
              handleBtnClick();
            }}
          >
            멤버등록
          </Button>
        </div>
      </Container>
      {isLoading && <SreenCoverLoading />}
    </>
  );
}

export default YearGroupMembersList;

const Container = styled.div`
  .list-table {
    /* background: ; */
    display: flex;
    flex-direction: column;
    .t-header {
      background-color: ${colors.gray.second};
      display: grid;
      grid-template-columns: 0.8fr 1fr 2fr 1fr 0.8fr;
      > * {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    ul.member-list {
      > li {
        display: grid;
        grid-template-columns: 0.8fr 1fr 2fr 1fr 0.8fr;
      }
    }
    .item {
      border: 1px solid black;
      padding: 2.5px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      word-break: break-all;
    }
  }
`;
