import React from "react";

function KakaoLoad() {
  return (
    <div>
      KakaoLoad
      <button
        onClick={() => {
          window.history.back();
        }}
      >
        닫기
      </button>
    </div>
  );
}

export default KakaoLoad;
