import { useEffect, useState } from "react";
import { sleep } from "src/utils";

const positions = [
  { id: 1, name: "회장" },
  { id: 2, name: "총무" },
  { id: 3, name: "멤버" },
  { id: 4, name: "경기이사" },
];
const getLocationTypes = async () => {
  // axios ...
  await sleep(500);
  return positions;
};

function useGroupPositon() {
  const [groupLocations, setGroupLocations] = useState<{ id: number; name: string }[]>();

  useEffect(() => {
    (async () => {
      const fetchedGroupLocations = await getLocationTypes();
      setGroupLocations(fetchedGroupLocations);
    })();
  }, []);

  return groupLocations;
}

export default useGroupPositon;
