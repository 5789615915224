import React, { ReactNode, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { VscTriangleDown } from "react-icons/vsc";
import Label from "./Label";
import Modal from "./Modal";
import { FaCheck } from "react-icons/fa";
import { colors } from "src/styles/theme";
import { useStopBackScroll } from "src/hooks";
import { MdOutlineRadioButtonUnchecked, MdOutlineRadioButtonChecked } from "react-icons/md";

export function usePopupSelectV2(args?: { defaultSelectIds?: number[] }) {
  // TODO: useModal hook 으로 리팩토링

  const [isPopupShown, setIsPopupShown] = useState(false);
  const [activeIds, setActiveIds] = useState<number[]>([]);

  const [activeIdsToReturn, setActiveIdsToReturn] = useState<number[]>([]);
  const reset = () => {
    setActiveIds([]);
  };
  const propsToInject = {
    isPopupShown,
    setIsPopupShown,
    activeIdsToInject: activeIds,
    setActiveIdsToInject: setActiveIds,
    activeIdsToReturn,
    setActiveIdsToReturn,
  };

  useEffect(() => {
    if (args?.defaultSelectIds?.length) {
      setActiveIdsToReturn([...(args?.defaultSelectIds || [])]);
      setActiveIds([...(args?.defaultSelectIds || [])]);
    }
  }, [JSON.stringify(args?.defaultSelectIds)]);

  const PopupSelect = useCallback(
    (props: {
      items: { id: number; name: string }[];
      placeholder?: string;
      label?: string;
      inputId?: string;
      tabIndex?: number;
      boldLabel?: boolean;
      isEssential?: boolean;
      multiple?: boolean;
      activeIdsToInject: number[];
      setActiveIdsToInject: React.Dispatch<React.SetStateAction<number[]>>;
      activeIdsToReturn: number[];
      setActiveIdsToReturn: React.Dispatch<React.SetStateAction<number[]>>;
      onSubmit?: (activeObejcts: any[]) => any;
      // activeIds: any;
      // setActiveIds: any;
    }) => {
      return (
        <>
          <Container className="" onClick={() => setIsPopupShown(true)}>
            {props.label && (
              <Label
                content={props.label}
                isEssential={props.isEssential}
                className={`${props.boldLabel ? "bold" : ""} mb-1`}
              />
            )}
            <div
              className="content-main input-box-type d-flex jc-sb ai-c"
              id={props.inputId || `${Date.now()}-group-c-s`}
              tabIndex={props.tabIndex}
            >
              {(props.activeIdsToReturn?.length || 0) < 1 ? (
                <>
                  <div className="showing placeholder">{props.placeholder}</div>
                </>
              ) : (
                <>
                  {props.multiple ? (
                    <>
                      {props.items
                        .filter((item) => props.activeIdsToReturn?.includes(item.id))
                        .map((i) => i.name)
                        .join(", ")}
                    </>
                  ) : (
                    <>
                      <div className="showing">
                        {props.items.find((i) => i.id === props.activeIdsToReturn?.[0])?.name}
                      </div>
                    </>
                  )}
                </>
              )}
              <VscTriangleDown className="triangle" />
            </div>
          </Container>
          {isPopupShown && (
            <Inner>
              <Modal
                title={props.label ? props.label : null}
                btnType={props.multiple ? "confirm" : "none"}
                setModalState={setIsPopupShown}
                onConfirm={() => {
                  props.setActiveIdsToReturn(props.activeIdsToInject);
                  if (props.onSubmit) {
                    props.onSubmit(
                      props.items.filter((item) => {
                        return props.activeIdsToInject?.some((id: any) => id === item.id);
                      })
                    );
                  }
                }}
                content={
                  <ModalContentContainer>
                    <ul>
                      {props.items.map((item) => {
                        return (
                          <li
                            onClick={() => {
                              if (props.activeIdsToInject?.includes(item.id)) {
                                props.setActiveIdsToInject((aIds: any) => {
                                  return aIds.filter((aid: any) => aid !== item.id);
                                });

                                return;
                              }
                              if (props.multiple) {
                                props.setActiveIdsToInject((aids: any) => [...aids, item.id]);
                                return;
                              } else {
                                props.setActiveIdsToInject([item.id]);
                                props.setActiveIdsToReturn([item.id]);
                                if (props.onSubmit) {
                                  props.onSubmit(
                                    props.items.filter((item) => {
                                      return props.activeIdsToInject?.some(
                                        (id: any) => id === item.id
                                      );
                                    })
                                  );
                                }
                                setTimeout(() => {
                                  setIsPopupShown(false);
                                }, 100);
                              }
                            }}
                            key={item.id}
                            className={
                              props.activeIdsToInject?.includes(item.id)
                                ? "active-true"
                                : "active-false"
                            }
                          >
                            {props.activeIdsToInject?.includes(item.id) ? (
                              <MdOutlineRadioButtonChecked size={25} />
                            ) : (
                              <MdOutlineRadioButtonUnchecked size={25} />
                            )}
                            <div>{item.name}</div>
                          </li>
                        );
                      })}
                    </ul>
                  </ModalContentContainer>
                }
              ></Modal>
            </Inner>
          )}
        </>
      );
    },
    [isPopupShown]
  );
  return { PopupSelect, propsToInject, activeIds: activeIdsToReturn, reset };
}

const Container = styled.div`
  cursor: pointer;
  label {
    display: inline-block;
  }
  .content-main {
  }
  .showing {
    &.placeholder {
      color: ${colors.gray.first};
      font-size: 12px;
    }
  }
  .triangle {
  }
`;
const Inner = (props: { children: ReactNode }) => {
  useStopBackScroll();
  return <>{props.children}</>;
};

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  h4 {
    padding: 10px;
  }

  ul {
    max-height: 70vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    transition: all 0.15s;

    li {
      cursor: pointer;
      /* padding: 10px 0px; */
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 15px;
      font-size: 18px;
      transition: all 0.15s;
      svg {
        color: ${colors.gray.first};
        transition: all 0.15s;
      }
      &.active-true {
        svg {
          color: ${colors.green.first};
        }
      }
    }
  }
`;
