import styled from "styled-components";

export default function TestComp() {
  return (
    <Container>
      test-component
      <h2>dd</h2>
    </Container>
  );
}

const Container = styled.div`
  background-color: coral;
`;
