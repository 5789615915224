import { colors } from "src/styles/theme";
import styled from "styled-components";

export default function ProcessHeader(props: {
  left?: { active: boolean; msg: string };
  right?: { active: boolean; msg: string };
  className?: string;
}) {
  return (
    <Container className={props?.className}>
      <div className={`left ${props?.left?.active ? "process-act" : ""} `}>{props?.left?.msg}</div>
      <div>{">"}</div>
      <div className={`right ${props?.right?.active ? "process-act" : ""} `}>
        {props?.right?.msg}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 10px;
  font-weight: bold;
  /* color: ${colors.gray.second}; */
  .process-act {
    color: ${colors.green.second};
  }
`;
