import { atom } from "recoil";
import {
  YearBookingStateType,
  YearBookingStatus,
  GroupDataType,
  BookingApplicationType,
  BookingClubRelDataType,
} from "src/types";

// const mainLayout = atom({
//   key: "init",
//   default: {
//     isInit: false,
//   },
// });

// type Position = "member" | "executive" | "president";

// type GroupDataType = {
//   id: false | number;
//   group_logo_url: string | undefined;
//   name: string;
//   members: { id: number; position: Position; phone_number: string; teeshot_user_id: number | false }[];
// };

const group = atom<GroupDataType>({
  key: "group",
  default: { id: false, group_logo_url: "", name: "", members: [] },
});

const init = atom({
  key: "init",
  default: {
    isInit: false,
  },
});

const bookingApplication = atom<BookingApplicationType | null>({
  key: "bookingApplication",
  default: null,
});

const bookingClubRelData = atom<BookingClubRelDataType | null>({
  key: "bookingClubRelData",
  default: null,
});

const user = atom({
  key: "user",
  default: {
    id: 0,
    realname: "",
    username: "",
    phone: "",
    gender: "",
    age: 0,
  },
});

export const recoilStates = {
  group,
  init,
  user,
  bookingApplication,
  bookingClubRelData,
  // mainLayout
};
