import React from "react";
import styled from "styled-components";

export default function Divider(props: { color?: string; width?: string; className?: string }) {
  return (
    <Container
      className={props.className}
      color={props.color || "lightGray"}
      width={props.width || "0.5px"}
    ></Container>
  );
}

const Container = styled.div<{ color: string; width: string }>`
  width: 100%;
  height: ${(p) => p.width};
  background-color: ${(p) => p.color};
  margin: 10px 0px;
`;
