import styled from "styled-components";
import Portal from "./Portal";
import Loading from "./Loading";

export default function SreenCoverLoading() {
  return (
    <Portal isRootPortal={true}>
      <BackGround>
        <Loading size={"55px"} />
      </BackGround>
    </Portal>
  );
}

const BackGround = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
  background-color: #41414167;
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 100; */
`;
