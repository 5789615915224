export default function dateFormat(date: Date, withTime = false) {
  try {
    const nd = new Date(date);
    const yy = String(nd.getFullYear()).slice(2, 4);
    const mm = String(nd.getMonth() + 1).padStart(2, "0");
    const dd = String(nd.getDate()).padStart(2, "0");
    const newDateString = `${yy}.${mm}.${dd}`;
    const timeString = nd.toLocaleTimeString("kr-KR", {
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h23",
    });
    const dayStr = {
      0: "(일)",
      1: "(월)",
      2: "(화)",
      3: "(수)",
      4: "(목)",
      5: "(금)",
      6: "(토)",
    }[nd.getDay()];
    return `${newDateString} ${dayStr}${withTime ? ` ${timeString}` : ""}`;
  } catch {
    console.log("error on dateFormat function");
    return null;
  }
}
