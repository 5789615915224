import { useEffect, useMemo, useState } from "react";
import ProcessHeader from "../ProcessHeader";
import { sleep } from "src/utils";
import ContentBox from "../ContentBox";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import styled from "styled-components";
import { bookingDummys } from "src/services/dummys";
import Button from "../Button";
import usePopupPage from "../PopupPage";
import { BookingConditionContents } from "../PopupPageContents";
import Stepper from "../Stepper";
import Animation from "../Animation";

export default function PaymentWating() {
  useEffect(() => {
    (async () => {
      await sleep();
    })();
    return () => {};
  }, []);

  const [bookingApplication, setBookingApplication] = useRecoilState(
    recoilStates.bookingApplication
  );

  const clubRelData = useMemo(() => {
    return bookingApplication?.clubRels.find((cr) => cr.year_booking_status_id === 4);
  }, [bookingApplication]);
  return (
    <Container className="d-flex fd-c gap-2">
      {/* <ProcessHeader
        left={{ active: false, msg: "골프장 확정 완료" }}
        right={{ active: true, msg: "보증금을 결제해 주세요." }}
      /> */}
      <Stepper
        className="page-pd-negative-side page-pd-negative-top"
        currentIdx={3}
        title="연단체 부킹확정!"
        steps={[
          { label: <>단체등록</>, icon: "group-register" },
          { label: "부킹 신청", icon: "booking-register" },
          { label: "조건 협의", icon: "select-club" },
          { label: "부킹 확정", icon: "confirm-club" },
          // {
          //   label: (
          //     <div className="d-flex fd-c ai-c">
          //       <div>보증금</div>
          //       <div>확인</div>
          //     </div>
          //   ),
          //   icon: "money",
          // },
          // { label: "멤버 등록", icon: "invite-member" },
        ]}
      />

      <div className="ani-box d-flex jc-c ai-c">
        <Animation jsonFetchPath={`${process.env.PUBLIC_URL}/party.json`} loop={false} />
      </div>

      <ContentBox
        colorTheme="gray"
        content={
          <div className="d-flex fd-c jc-c ai-c gap-1 pd-2">
            <p>축하합니다!</p>
            <p>연단체 부킹이 확정되었습니다.</p>
            <p>보증금 및 일정안내를 기다려주세요.</p>
          </div>
        }
      />
    </Container>
  );
}

const Container = styled.div`
  .ani-box {
    > div {
      max-width: 250px;
    }
    min-width: 1px;
  }
`;
