import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { BiSave } from "react-icons/bi";
import {
  Button,
  Divider,
  DoubleButton,
  Input,
  Label,
  SreenCoverLoading,
  usePopupPage,
  useRadioBoxGroup,
} from "src/components";
import { recoilStates } from "src/recoil/recoilStates";
import styled from "styled-components";
import { TeamType } from "src/types";
import { MemberSearchPopupContents } from "src/components/PopupPageContents";
import { parseJsonStr, sleep } from "src/utils";
import { useNavigate } from "react-router-dom";
import { createTeeshotAxiosInstance } from "src/http";
import { TeamDataType } from "src/types";
import { useAlert, useConfirm, useRefetchBookings } from "src/hooks";
import { usePopupSelectV2 } from "src/components/PopupSelect";

export default function YearGroupTeamRegister() {
  const navigate = useNavigate();

  const [group, setGroup] = useRecoilState(recoilStates.group);

  const [bookingApplication, setBookingApplication] = useRecoilState(
    recoilStates.bookingApplication
  );
  const clubRelData = bookingApplication?.clubRels?.find((cr) => cr.year_booking_status_id === 6);
  const rounds = clubRelData?.year_booking_round_dates;

  const {
    PopupSelect: DatePopupSelect,
    activeIds: activeDateIds,
    propsToInject,
  } = usePopupSelectV2();

  const [teams, setTeams] = useState<TeamDataType[]>();

  const [forcedRerenderFlag, setForcedRerenderFlag] = useState(true);

  const currentRound = rounds?.find((r) => r.id === activeDateIds?.[0]);
  const hasSavedData = Boolean(currentRound?.is_temp_saved) || Boolean(currentRound?.is_fixed);

  const { RadioBoxGroup: AwardsBoxGroup, activeIds: activeAwardsIds } = useRadioBoxGroup({
    multipleValue: true,
    boxType: "round",
    multiLineColumnCount: 4,
    defaultSelectedIds: hasSavedData
      ? parseJsonStr(currentRound?.award_ids_arr_json_str || "")?.map((id: any) => +id) || []
      : parseJsonStr(bookingApplication?.award_ids_arr_json_str || "")?.map((id: any) => +id) || [],
    // fontSize: "12px",
    items: [
      { id: 1, name: "우승" },
      { id: 2, name: "준우승" },
      { id: 3, name: "3위" },
      { id: 4, name: "행운상" },
      { id: 5, name: "특별상" },
      { id: 6, name: "메달리스트" },
      { id: 7, name: "롱게스트" },
      { id: 8, name: "니어리스트" },
      { id: 9, name: "매너상" },
      { id: 10, name: "베스트드레서", fontSize: "12px" },
    ],
  });

  const { RadioBoxGroup: MealActiveBoxGroup, activeIds: mealActiveIds } = useRadioBoxGroup({
    boxType: "round",
    defaultSelectedIds: hasSavedData
      ? [currentRound?.meal_type_id ? 1 : 2]
      : [bookingApplication?.meal_type_id ? 1 : 2],
    items: [
      { id: 1, name: "유" },
      { id: 2, name: "무" },
    ],
  });
  const { RadioBoxGroup: MealTypeBoxGroup, activeIds: mealTypeActiveIds } = useRadioBoxGroup({
    boxType: "round",
    defaultSelectedIds: hasSavedData
      ? currentRound?.meal_type_id
        ? [currentRound?.meal_type_id]
        : []
      : bookingApplication?.meal_type_id
      ? [bookingApplication?.meal_type_id]
      : [],
    items: [
      { id: 1, name: "조식" },
      { id: 2, name: "중식" },
      { id: 3, name: "석식" },
    ],
  });
  const { RadioBoxGroup: RoomActiveBoxGroup, activeIds: roomActiveIds } = useRadioBoxGroup({
    boxType: "round",
    defaultSelectedIds: hasSavedData
      ? [currentRound?.room_type_id ? 1 : 2]
      : [bookingApplication?.room_type_id ? 1 : 2],
    items: [
      { id: 1, name: "유" },
      { id: 2, name: "무" },
    ],
  });

  const { RadioBoxGroup: RoomTypeBoxGroup, activeIds: roomTypeActiveIds } = useRadioBoxGroup({
    boxType: "round",
    defaultSelectedIds: hasSavedData
      ? currentRound?.room_type_id
        ? [currentRound?.room_type_id]
        : []
      : bookingApplication?.room_type_id
      ? [bookingApplication?.room_type_id]
      : [],
    items: [
      { id: 1, name: "라운드 전" },
      { id: 2, name: "라운드 후" },
    ],
  });

  const { RadioBoxGroup: ShopActiveBoxGroup, activeIds: shopActiveIds } = useRadioBoxGroup({
    boxType: "round",

    defaultSelectedIds: hasSavedData
      ? [currentRound?.proshop_item ? 1 : 2]
      : [bookingApplication?.proshop_item ? 1 : 2],
    items: [
      { id: 1, name: "유" },
      { id: 2, name: "무" },
    ],
  });

  const { value: shopItemValue, onChange: handleShopItemValueChange } = Input.hooks.useInput(
    hasSavedData ? currentRound?.proshop_item : bookingApplication?.proshop_item || ""
  );
  const { value: etcValue, onChange: handleEtcValue } = Input.hooks.useInput("");

  useEffect(() => {
    setForcedRerenderFlag(false);
    setTimeout(() => {
      setForcedRerenderFlag(true);
    }, 0);
  }, [activeDateIds]);

  useEffect(() => {
    const roundData = rounds?.find((r) => r.id === activeDateIds?.[0]);

    if (!roundData?.teams || roundData?.teams?.length < 1) {
      setTeams([
        { team_number: 1, year_booking_round_date_team_rel_booking_group_member: [] },
        { team_number: 2, year_booking_round_date_team_rel_booking_group_member: [] },
      ]);
      return;
    }
    setTeams(JSON.parse(JSON.stringify(roundData.teams)) as any);
  }, [bookingApplication, activeDateIds[0]]);
  const {
    PopupComponent: MemeberSearchPopup,
    openPopup: openMemberSearchPopup,
    closePopup: closeMemberSearchPopup,
  } = usePopupPage("member-search");

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [currentSelectedTeamNumber, setCurrentSelectedTeamNumber] = useState(0);

  const refetchBookings = useRefetchBookings();
  const alert = useAlert();
  const confirm = useConfirm();
  const handleSubmit = async (tempSave?: boolean) => {
    const notEmptyAddingTeams = teams?.filter((t) => {
      return (
        (t.year_booking_round_date_team_rel_booking_group_member?.length &&
          t.year_booking_round_date_team_rel_booking_group_member?.length > 0) ||
        t.deleteMembers?.length
      );
    });

    if (!tempSave && (!activeDateIds[0] || !notEmptyAddingTeams?.length)) {
      alert("라운드 일자 및 팀 정보를 입력하세요");
      return;
    }
    try {
      setIsSubmitLoading(true);
      const teamSubmitRes = await createTeeshotAxiosInstance().post(
        `/yearBooking/booking/team${tempSave ? "?tempSave=1" : ""}`,
        {
          submitData: {
            roundId: activeDateIds[0],
            teams: notEmptyAddingTeams,
            awardIds: activeAwardsIds?.length ? activeAwardsIds : null,
            meal_type_id: mealActiveIds?.[0] === 1 ? mealTypeActiveIds?.[0] : null,
            room_type_id: roomActiveIds?.[0] === 1 ? roomTypeActiveIds?.[0] : null,
            proshop_item: shopActiveIds?.[0] === 1 ? shopItemValue : null,
            etc: etcValue?.trim() ? etcValue?.trim() : null,
          },
        }
      );
      if (!teamSubmitRes.data.success) {
        throw new Error();
      }
      await refetchBookings();
      alert({
        content: tempSave ? "임시저장 되었습니다." : "팀 및 라운드 정보 전달 완료.",
      });
    } catch (e) {
      console.log("e :", e);
      setIsSubmitLoading(false);
      alert({
        content: "팀 등록 실패",
        onClose: () => {
          alert.resume();
        },
      });
      await alert.pause();
      window.location.reload();
    } finally {
      setIsSubmitLoading(false);
    }

    return;
  };
  if (!forcedRerenderFlag) {
    return <></>;
  }
  return (
    <>
      {isSubmitLoading && <SreenCoverLoading />}
      <Container className="flex-1 d-flex fd-c gap-1">
        <div className="content flex-1 d-flex fd-c gap-3">
          <p>연간 부킹 팀원 등록은 3일전까지 등록해주시기 바랍니다.</p>
          <DatePopupSelect
            label="부킹일자"
            boldLabel
            placeholder="부킹일자를 선택하세요."
            {...propsToInject}
            items={
              rounds?.map((rd) => ({
                id: rd.id,
                name: rd.round_date,
              })) ?? []
            }
          />
          <Divider />
          <div className="team-and-options">
            {activeDateIds?.length && currentRound?.is_fixed ? <div className="cover" /> : null}
            {activeDateIds?.length ? (
              <>
                <div className={`d-flex fd-c gap-3  ${currentRound?.is_fixed ? "no-click" : ""}`}>
                  <div>
                    {currentRound?.is_fixed ? (
                      <p className="complete">멤버 및 옵션 제출 완료</p>
                    ) : (
                      <></>
                    )}
                  </div>
                  {teams?.map((t, idx) => {
                    const memberCount =
                      t.year_booking_round_date_team_rel_booking_group_member?.length;
                    return (
                      <div key={`${t.team_number}-${idx}`} className={`d-flex fd-c jc-sb`}>
                        <div>{t.team_number} 팀</div>
                        <div className="d-flex gap-2">
                          <div className="members-container flex-1">
                            {t.year_booking_round_date_team_rel_booking_group_member?.map((m) => {
                              const name = group.members.find(
                                (_m) => _m.id === m.booking_group_member_id
                              )?.name;
                              return (
                                <div
                                  className="box"
                                  // key={`${m.id}-${m.name}`}
                                  key={`${m.booking_group_member_id}`}
                                  onClick={() => {
                                    confirm({
                                      title: "팀 멤버 삭제",
                                      content: (
                                        <div>
                                          <span className="bold">{t.team_number}팀</span>에서{" "}
                                          <span className="bold">{name}</span>님을 제외
                                          시키시겠습니까?
                                        </div>
                                      ),
                                      onConfirm: () => {
                                        setTeams((teams) => {
                                          if (t.id && m.id) {
                                            if (!t.deleteMembers) {
                                              t.deleteMembers = [];
                                            }
                                            t.deleteMembers.push({
                                              team_id: t.id,
                                              team_member_id: m.id,
                                            });
                                          }
                                          t.year_booking_round_date_team_rel_booking_group_member =
                                            t.year_booking_round_date_team_rel_booking_group_member?.filter(
                                              (_m) => _m.id !== m.id
                                            );
                                          return [...(teams ?? [])];
                                        });
                                      },
                                    });

                                    // if (
                                    //   window.confirm(
                                    //     `${t.team_number}팀에서 ${name}님을 제외 시키시겠습니까?`
                                    //   )
                                    // ) {
                                    //   setTeams((teams) => {
                                    //     if (t.id && m.id) {
                                    //       if (!t.deleteMembers) {
                                    //         t.deleteMembers = [];
                                    //       }
                                    //       t.deleteMembers.push({
                                    //         team_id: t.id,
                                    //         team_member_id: m.id,
                                    //       });
                                    //     }
                                    //     t.year_booking_round_date_team_rel_booking_group_member =
                                    //       t.year_booking_round_date_team_rel_booking_group_member?.filter(
                                    //         (_m) => _m.id !== m.id
                                    //       );
                                    //     return [...(teams ?? [])];
                                    //   });
                                    // }
                                  }}
                                >
                                  {name}
                                </div>
                              );
                            })}
                            {(() => {
                              return new Array(4 - (memberCount ?? 0)).fill("-").map((x, idx) => {
                                return (
                                  <div className="box" key={`${idx}-emptybox-${t.team_number}`}>
                                    {x}
                                  </div>
                                );
                              });
                            })()}
                          </div>
                          <Button
                            $colorTheme="lightGray"
                            $round
                            onClick={() => {
                              if (
                                (t.year_booking_round_date_team_rel_booking_group_member?.length ??
                                  0) >= 4
                              ) {
                                alert(
                                  "멤버가 가득찼습니다. 멤버를 해제하시려면 멤버이름을 터치하세요."
                                );
                                return;
                              }
                              setCurrentSelectedTeamNumber(t.team_number);
                              openMemberSearchPopup();
                            }}
                          >
                            등록
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Button
                  className={`mt-2 ${currentRound?.is_fixed ? "no-click" : ""}`}
                  $round
                  $colorTheme="green"
                  onClick={() => {
                    setTeams((teams) => {
                      return [
                        ...(teams ?? []),
                        {
                          team_number: Math.max(...(teams?.map((t) => t.team_number) ?? [0])) + 1,
                          year_booking_round_date_team_rel_booking_group_member: [],
                        },
                      ];
                    });
                  }}
                >
                  팀 추가
                </Button>
                <Divider />

                <div className="opt-area d-flex fd-c gap-3">
                  <div className="d-flex fd-c gap-1">
                    <Label content="시상요청" className="bold" />
                    <AwardsBoxGroup />
                  </div>
                  <div className="d-flex fd-c gap-1">
                    <Label content="식사" className="bold" />
                    <div className="d-flex mb-2">
                      <div className="flex-1">
                        <MealActiveBoxGroup />
                      </div>
                      <div className="flex-1" />
                    </div>
                    {mealActiveIds?.[0] === 1 && <MealTypeBoxGroup />}
                  </div>
                  <div className="d-flex fd-c gap-1">
                    <Label content="룸이용" className="bold" />
                    <div className="d-flex mb-2">
                      <div className="flex-1">
                        <RoomActiveBoxGroup />
                      </div>
                      <div className="flex-1" />
                    </div>
                    {roomActiveIds?.[0] === 1 && <RoomTypeBoxGroup />}
                  </div>
                  <div className="d-flex fd-c gap-1">
                    <Label content="프로샵 이용" className="bold" />
                    <div className="d-flex mb-2">
                      <div className="flex-1">
                        <ShopActiveBoxGroup />
                      </div>
                      <div className="flex-1" />
                    </div>
                    {shopActiveIds?.[0] === 1 && (
                      <div className="ml-5">
                        <Input
                          label={"시상품"}
                          attr={{
                            value: shopItemValue,
                            onChange: handleShopItemValueChange,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <Input
                      label={"기타사항"}
                      attr={{ value: etcValue, onChange: handleEtcValue }}
                    />
                  </div>
                </div>
                <div>
                  {currentRound?.is_fixed ? (
                    <p className="mt-2 complete">멤버 및 옵션 제출 완료</p>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <>
                <div>라운드 일자를 선택하세요.</div>
              </>
            )}
            {activeDateIds?.length ? (
              <div className="d-flex jc-e mb-5">
                <Button
                  $buttonSize="sm"
                  $colorTheme="green"
                  className="bold pd-2 d-flex gap-1"
                  $round
                  onClick={() => handleSubmit(true)}
                  disabled={!Boolean(activeDateIds?.length) || Boolean(currentRound?.is_fixed)}
                >
                  <BiSave fontSize={22} /> 임시저장
                </Button>
              </div>
            ) : null}
          </div>
        </div>

        <DoubleButton
          nagativeMargin
          left={{ name: "취소", size: "md", themeColor: "lightGray", onClick: () => navigate(-1) }}
          right={{
            name: "등록",
            size: "md",
            themeColor: "green",

            buttonCompProps: {
              disabled:
                !Boolean(activeDateIds?.length) ||
                Boolean(currentRound?.is_fixed) ||
                teams?.every(
                  (t) => t?.year_booking_round_date_team_rel_booking_group_member?.length == 0
                ),
            },
            onClick: () => {
              confirm({
                title: "라운드 정보 전송",
                content:
                  "더 이상 조편성 및 라운드 옵션을 수정 할 수 없습니다. 조 편성 및 라운드 옵션을 클럽에 전달하시겠습니까?",
                onConfirm: () => handleSubmit(),
              });
            },
          }}
        />
      </Container>
      <MemeberSearchPopup
        headerMsg="팀원 선택"
        content={
          <>
            <MemberSearchPopupContents
              teams={teams}
              closeMe={() => {
                closeMemberSearchPopup();
              }}
              currentTeamNumber={currentSelectedTeamNumber}
              setTeams={setTeams}
            />
          </>
        }
      />
    </>
  );
}

const Container = styled.div`
  height: 100%;
  .members-container {
    display: flex;
    gap: 10px;
    > .box {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid black;
      width: 50px;
    }
  }
  p.complete {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
  }

  .team-and-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    .cover {
      width: calc(100% + 30px);
      height: calc(100% + 10px);
      position: absolute;
      left: -15px;
      top: -5px;
      margin-right: -15px;

      background-color: #00000034;
    }
  }
`;

const TeamSelectorContainer = styled.div``;
