import axios from "axios";

export const createTeeshotAxiosInstance = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_TEESHOT_SERVER_URL,
    timeout: 2000,
    headers: {
      Authorization: (() => {
        const token = localStorage.getItem("auth-token");
        return `Bearer ${token}`;
      })(),
    },
  });
};
