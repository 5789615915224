import { ReactNode } from "react";
import styled from "styled-components";
import Portal from "./Portal";
import { colors } from "src/styles/theme";
import Button from "./Button";

export default function Modal(props: {
  content: ReactNode;
  title?: ReactNode;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm?: Function;
  rejectConfirmCondition?: boolean;
  rejectConfirmMsg?: string;
  isRootPortal?: boolean;
  btnType?: "none" | "confirm" | "confirm-cancel";
}) {
  return (
    <>
      <Portal isRootPortal={props.isRootPortal === undefined ? true : props.isRootPortal}>
        <BackGround
          onClick={(e) => {
            props.setModalState(false);
          }}
        >
          <ModalBox
            onClick={(e) => {
              // e.preventDefault();
              e.stopPropagation();
            }}
          >
            {props.title && <div className="header">{props.title}</div>}
            <div className="content">{props.content}</div>
            {(() => {
              switch (props.btnType) {
                case "none": {
                  return "";
                }
                case "confirm":
                default: {
                  return (
                    <div className="content-bottom">
                      <Button
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                        $colorTheme="greenReverse"
                        $buttonSize="sm"
                        onClick={() => {
                          if (props.rejectConfirmCondition) {
                            alert(
                              props.rejectConfirmMsg
                                ? props.rejectConfirmMsg
                                : "진행할 수 없습니다."
                            );
                            return;
                          }
                          props.onConfirm && props.onConfirm();
                          props.setModalState(false);
                        }}
                      >
                        확인
                      </Button>
                    </div>
                  );
                }
              }
              return "";
            })()}
          </ModalBox>
        </BackGround>
      </Portal>
    </>
  );
}

const BackGround = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
  background-color: #00000092;
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 100; */
`;

const ModalBox = styled.div`
  width: 90%;
  max-width: 500px;
  min-height: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;

  box-shadow: rgba(31, 31, 31, 0.35) 0px 5px 15px;

  .header {
    padding: 20px;
    font-weight: bold;
    font-size: 18px;
    border-bottom: 1px solid ${colors.gray.third};

  }
  .content {
    flex: 1;
    padding: 20px;

    display: flex;
    flex-direction: column;
  }
  .content-bottom {
    padding: 20px;

    display: flex;
    justify-content: flex-end;
  }
`;
