import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch";
import { styled } from "styled-components";
import Button from "./Button";
import { LuZoomIn, LuZoomOut } from "react-icons/lu";
import { colors } from "src/styles/theme";

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();

  return (
    <span className="btns">
      <Button $colorTheme="darkGray" $transparent onClick={() => zoomIn()}>
        <LuZoomIn size={24} />
      </Button>
      <Button $colorTheme="darkGray" $transparent onClick={() => zoomOut()}>
        <LuZoomOut size={24} />
      </Button>
    </span>
  );
};

export default function ImgZoomContainer(props: { imgUrl: string }) {
  return (
    <ZoomBox>
      <TransformWrapper>
        <>
          <Controls />
          <TransformComponent>
            <img src={props.imgUrl} alt="test" />
          </TransformComponent>
        </>
      </TransformWrapper>
    </ZoomBox>
  );
}

const ZoomBox = styled.div`
  position: relative;
  > div {
    width: 100%;
  }
  > div > div {
    width: 100%;
  }
  img {
    width: 100%;
    height: 500px;
    object-fit: contain;
  }
  border: 2px solid ${colors.gray.third};
  .btns {
    position: absolute;
    display: flex;
    z-index: 10;
    right: 10px;
    bottom: 10px;
    gap: 5px;
  }
`;
