import { useMemo } from "react";
import { useRecoilState } from "recoil";
import { ContentBox } from "src/components";
import { recoilStates } from "src/recoil/recoilStates";
import { addDays, dateFormat } from "src/utils";
import styled from "styled-components";

function Deposit() {
  const [bookingApplication, setBookingApplication] = useRecoilState(
    recoilStates.bookingApplication
  );

  const clubRelData = useMemo(() => {
    return bookingApplication?.clubRels.find((cr) => cr.year_booking_status_id === 4);
  }, [bookingApplication]);

  const day = addDays(new Date(clubRelData?.updated_at || Date.now()), 7);
  return (
    <Container className="flex-1">
      <p className="pay-comment">
        {dateFormat(day)} 까지 아래와 같이 보증금을 결제해 주시기 바랍니다.
      </p>
      <ContentBox
        colorTheme="gray"
        content={
          <div className="flex-1 d-flex jc-sa fd-c">
            <div>
              보증금 합계:{" "}
              {(
                (clubRelData?.meal_deposit ?? 0) + (clubRelData?.round_deposit ?? 0)
              ).toLocaleString()}{" "}
              원
            </div>
            <div>
              <div>예금주: (주)에이치비엠피</div>
              <div>은행명: 우리은행</div>
              <div>계좌번호: 1005-302-929567</div>
            </div>
          </div>
        }
      />
      <p className="pay-comment">입금확인: 티샷 02-2277-3489</p>
    </Container>
  );
}

export default Deposit;

const Container = styled.div`
  .pay-comment {
    width: 250px;
    align-self: center;
    padding: 15px 0px;
  }
`;
