import React, { ComponentProps, useEffect } from "react";
import styled, { css } from "styled-components";
import { colors } from "src/styles/theme";

export default function Button(props: ComponentProps<typeof StyledButton>) {
  return <StyledButton {...props} />;
}
const buttonThemeObj = {
  white: {
    bgc: "#fff",
    fontColor: "black",
    outLine: "black",
  },
  green: {
    bgc: colors.green.first,
    fontColor: "#fff",
    outLine: "black",
  },
  greenReverse: {
    bgc: "#ffffff00",
    fontColor: colors.green.first,
    outLine: colors.green.first,
  },
  lightGreen: {
    bgc: colors.green.second,
    fontColor: "#fff",
    outLine: colors.green.second,
  },
  black: {
    bgc: "#2c2c2c",
    fontColor: "#fff",
    outLine: colors.black.default,
  },
  gray: {
    bgc: colors.gray.first,
    fontColor: "#fff",
    outLine: colors.black.default,
  },
  darkGray: {
    bgc: "#595959",
    fontColor: "#fff",
    outLine: colors.black.default,
  },
  lightGray: {
    bgc: colors.gray.second,
    fontColor: "#000",
    outLine: colors.black.default,
  },
  lightGray2: {
    bgc: colors.gray.fourth,
    fontColor: "#000",
    outLine: colors.black.default,
  },
  lightGray3: {
    bgc: colors.gray.third,
    fontColor: "#000",
    outLine: colors.black.default,
  },
  yellow: {
    bgc: colors.yellow.first,
    fontColor: "#000",
    // outLine: colors.black.default,
    outLine: "black",
  },
  red: {
    bgc: colors.red.first,
    fontColor: "#fff",
    outLine: colors.black.default,
  },
};

const buttonSizeObj = {
  xs: { padding: "5px", fontSize: "12px", height: "30px" },
  sm: { padding: "10px", fontSize: "14px", height: "40px" },
  md: { padding: "15px", fontSize: "20px", height: "55px" },
};

type ButtonStyleType = {
  $outline?: boolean;
  $round?: boolean;
  $colorTheme?: keyof typeof buttonThemeObj;
  $buttonSize?: keyof typeof buttonSizeObj;
  $pointerEventNone?: boolean;
  $transparent?: boolean;
  disabled?: boolean;
};

const StyledButton = styled.button<ButtonStyleType>`
  ${(p) => {
    if (!p.$colorTheme) {
      p.$colorTheme = "white";
    }
    if (!p.$buttonSize) {
      p.$buttonSize = "sm";
    }
    if (!p.$outline) {
      p.$outline = false;
    }

    return css`
      border: ${p.$outline ? `2px solid ${buttonThemeObj[p.$colorTheme].outLine}` : "none"};
      background-color: ${buttonThemeObj[p.$colorTheme].bgc};
      color: ${buttonThemeObj[p.$colorTheme].fontColor};
      padding: ${buttonSizeObj[p.$buttonSize].padding};
      font-size: ${buttonSizeObj[p.$buttonSize].fontSize};
      height: ${buttonSizeObj[p.$buttonSize].height};
    `;
  }}

  ${(p) => {
    return p.$pointerEventNone
      ? css`
          pointer-events: none;
        `
      : "";
  }}
  ${(p) => {
    return p.$transparent
      ? css`
          opacity: 0.6;
        `
      : "";
  }}
  font-weight:bold;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border:none; */
  cursor: pointer;
  border-radius: ${(p) => (p.$round ? "10px" : "0px")};
  transition: all 0.15s;
  &:disabled {
    pointer-events: none;
    filter: opacity(0.7) brightness(0.7);
  }
  &:active {
    filter: brightness(0.75);
  }
`;
