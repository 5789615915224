import React, { useState } from "react";
import { BookingClubRelDataType } from "src/types";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Button from "../Button";
import Divider from "../Divider";
import styled from "styled-components";
import { dateFormat, parseJsonStr } from "src/utils";
import { colors } from "src/styles/theme";
import ContentBox from "../ContentBox";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import STATIC_DATA from "src/staticData";

export default function RoundAccodian(props: {
  clubRelData: BookingClubRelDataType;
  className?: string;
  defaultOpened?: boolean;
}) {
  const [isDatesOpened, setIsDatesOpened] = useState(Boolean(props.defaultOpened));
  const [bookingApplication, setBookingApplication] = useRecoilState(
    recoilStates.bookingApplication
  );
  const etcRequirements = bookingApplication?.etcRequirements?.length
    ? bookingApplication?.etcRequirements
    : null;

  const { clubRelData } = props;
  const clubName = clubRelData.club?.name;
  const club = clubRelData.club?.[0];
  const reservationAgent1Name = club?.reservationAgent1;
  const reservationAgent1Tel = club?.reservationAgentTel1;
  const reservePhone = club?.reserve_phone || club?.phone;
  const hasAgentData = reservationAgent1Name && reservationAgent1Tel;
  const hasPhone = Boolean(reservePhone);
  return (
    <>
      <Container className={`d-flex fd-c ${props.className ? props.className : ""}`}>
        <Button
          className="d-flex jc-sb pd-2 bold"
          $colorTheme="lightGray"
          onClick={() => {
            setIsDatesOpened((b) => !b);
          }}
        >
          상세일정 및 그린피
          {isDatesOpened ? <FaChevronUp /> : <FaChevronDown />}
        </Button>
        {isDatesOpened && (
          <div className={`date-list open-${isDatesOpened}`}>
            <ul className={`d-flex fd-c flex-1 `}>
              {clubRelData.year_booking_round_dates?.map((rd: any, idx: any) => {
                return (
                  <React.Fragment key={rd.id}>
                    <li className="d-flex gap-1 jc-sb flex-1">
                      <div className="flex-2 fs-4">{dateFormat(new Date(rd.round_date))}</div>
                      <div className="flex-3 d-flex fd-c">
                        <div className="d-flex jc-sb ai-c">
                          <div className="fs-3">(그린피)</div>{" "}
                          <div className="fs-4">{rd.green_fee?.toLocaleString()} 원</div>
                        </div>
                        <div className="d-flex jc-sb ai-c">
                          <div className="fs-3">(카트피)</div>{" "}
                          <div className="fs-4">{rd.cart_fee?.toLocaleString()} 원</div>
                        </div>
                        <div className="d-flex jc-sb ai-c">
                          <div className="fs-3">(캐디피)</div>{" "}
                          <div className="fs-4">{rd.caddy_fee?.toLocaleString()} 원</div>
                        </div>
                      </div>
                    </li>
                    {(clubRelData.year_booking_round_dates?.length || 0) - 1 !== idx && <Divider />}
                  </React.Fragment>
                );
              })}
            </ul>
            <Divider />
            <div className="d-flex">
              <div className="bold row-h">보증금</div>
              <div>
                <div className="d-flex gap-1">
                  <div>{clubRelData?.round_deposit?.toLocaleString()} 원</div>
                </div>
              </div>
            </div>
            <Divider />
            <div className="d-flex">
              <div className="bold row-h">객단가</div>
              <div>
                <div>{clubRelData?.meal_price_per_person?.toLocaleString()} 원</div>
              </div>
            </div>
            <Divider />

            <div className="d-flex">
              <div className="bold row-h">안내사항</div>
              <div>
                <div>{clubRelData?.etc_notification || " - "}</div>
              </div>
            </div>

            <Divider />
            <div className="d-flex fd-c gap-1">
              <div className="bold">경기 및 시상</div>
              <ContentBox
                content={
                  <div className="d-flex fd-c fs-3">
                    <div className="innerBox d-flex ai-c">
                      <div className="row-h">경기방식</div>
                      <div className="fw-n">
                        {bookingApplication?.game_type_id
                          ? STATIC_DATA.GAME_TYPE_DATA.find((d) => {
                              return d.id === bookingApplication?.game_type_id;
                            })?.name
                          : null}
                      </div>
                    </div>
                    <div className="innerBox d-flex">
                      <div className="row-h">티사용</div>
                      <div className="fw-n">
                        {bookingApplication?.game_type_id
                          ? STATIC_DATA.TEEBOX_TYPE_DATA.find((d) => {
                              return d.id === bookingApplication?.teebox_type_id;
                            })?.name
                          : null}
                      </div>
                    </div>
                    <div className="innerBox d-flex">
                      <div className="row-h">시상내용</div>
                      <div className="fw-n">
                        {(() => {
                          const awardIds = parseJsonStr(
                            bookingApplication?.award_ids_arr_json_str ?? ""
                          );
                          const hasAwards = awardIds && awardIds.length > 0;
                          if (hasAwards) {
                            const awards = STATIC_DATA.AWARDS_DATA.filter((d) => {
                              return awardIds.includes(d.id);
                            });
                            console.log("awards :", awards);
                            return awards
                              ?.map((a) => {
                                return a.name;
                              })
                              .join(", ");
                          }
                          return " - ";
                        })()}
                      </div>
                    </div>
                    <div className="innerBox d-flex">
                      <div className="row-h">요청사항</div>
                      <div className="fw-n">
                        {etcRequirements ? etcRequirements.map((e) => e.name)?.join(", ") : " - "}
                      </div>
                    </div>
                  </div>
                }
              />
            </div>

            <Divider />
            <div className="d-flex fd-c gap-1">
              <div className="bold">객단가 및 시설이용</div>
              <ContentBox
                content={
                  <div className="d-flex fd-c fs-3">
                    <div className="innerBox d-flex ai-c">
                      <div className="row-h">결제</div>

                      <div className="fw-n">
                        {STATIC_DATA.PAY_TYPE_DATA.find(
                          (d) => d.id === bookingApplication?.payment_type_id
                        )?.name || " - "}
                      </div>
                    </div>
                    <div className="innerBox d-flex">
                      <div className="row-h">식사</div>
                      <div className="fw-n">
                        {STATIC_DATA.MEAL_TYPE_DATA.find(
                          (d) => d.id === bookingApplication?.meal_type_id
                        )?.name || " - "}
                      </div>
                    </div>
                    <div className="innerBox d-flex">
                      <div className="row-h">프로샵</div>
                      <div className="fw-n">{bookingApplication?.proshop_item || " - "}</div>
                    </div>
                  </div>
                }
              />
            </div>

            <Divider />
            <div className="tel-and-tel-msg">
              <div className="tel">
                {(() => {
                  if (hasAgentData) {
                    return (
                      <div>
                        예약담당자 {reservationAgent1Name}:{reservationAgent1Tel}
                      </div>
                    );
                  }
                  if (hasPhone) {
                    return <div>예약연락처: {reservePhone}</div>;
                  }
                  return <div>티샷: 02-2277-3489 </div>;

                  return "";
                })()}
              </div>
              {}
              <p className="tel-msg">
                ☞ {"\u00a0"}문의가 필요한 경우 전화하여 협의하여 주시기 바랍니다.
              </p>
            </div>
          </div>
        )}
      </Container>
    </>
  );
}

const Container = styled.div`
  /* padding: 15px; */

  .date-list {
    /* padding: 15px; */
    padding: 15px;
    background-color: white;
    font-weight: normal;
    overflow: hidden;
    /* overflow-x: auto; */
    /* transition: all 0.5s cubic-bezier(0.84, 0.06, 0.18, 0.96); */
    /* transition: all 0.5s ease-in-out; */
    /* transition: height, max-height 1s linear; */
    /* &.open-true {
  
    }
    &.open-false {
      height: 0;
    
    } */
  }

  .row-h {
    width: 100px;
  }

  .innerBox {
    .row-h {
      width: 75px;
    }
  }

  .tel-and-tel-msg {
    font-weight: bold;
    font-size: 14px;
    background-color: ${colors.yellow.third};
    padding: 15px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
    .tel {
      color: ${colors.blue.third};
      margin-bottom: 5px;
    }
    .tel-msg {
    }
  }
`;
