import { ComponentProps, ReactNode, useId } from "react";
import { selector } from "recoil";
import { colors } from "src/styles/theme";
import styled, { css } from "styled-components";

const colorObj = {
  gray: {
    backGroundColor: colors.gray.third,
  },
  default: {
    backGroundColor: "white",
  },
};
type ColorThemeType = keyof typeof colorObj;

export default function ContentBox(props: {
  tabIndex?: number;
  id?: string;
  className?: string;
  header?: ReactNode;
  content?: ReactNode;
  asBtn?: boolean;
  onClick?: () => void;
  isSelectedBtn?: boolean;
  colorTheme?: ColorThemeType;
  styleProps?: ComponentProps<typeof Box>["style"];
}) {
  return (
    <Box
      id={props.id}
      tabIndex={props.tabIndex}
      style={props.styleProps}
      className={`${props.isSelectedBtn ? "selected" : ""}${
        props.className ? ` ${props.className}` : ""
      }`}
      $asBtn={props?.asBtn}
      $ColorTheme={props.colorTheme}
      onClick={() => {
        props?.onClick && props.onClick();
      }}
    >
      {props.header && <Header>{props.header}</Header>}
      <Content>{props.content}</Content>
    </Box>
  );
}

const Box = styled.div<{ $asBtn?: boolean; $ColorTheme?: ColorThemeType }>`
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80px;
  border: solid 1px ${colors.gray.second};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  background-color: ${(p) => colorObj?.[p.$ColorTheme ?? "default"].backGroundColor};
  /* font-weight: bold; */
  transition: all 0.15s;
  ${(p) => {
    if (p?.$asBtn) {
      return css`
        background-color: ${colors.gray.fourth};

        outline-style: solid;
        outline-color: #00000000;
        outline-width: 3px !important;
        outline-offset: -2px;
        &.selected {
          background-color: ${colors.gray.third};
          outline-color: ${colors.green.second};
        }
      `;
    }
  }};
`;
const Header = styled.header`
  display: flex;
  flex-direction: column;
  border: none;
  /* align-self: center; */
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
