import React, { useEffect } from "react";

function useStopBackScroll() {
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = "hidden";
    }

    return () => {
      if (body) {
        body.style.overflow = "auto";
      }
    };
  }, []);
}

export default useStopBackScroll;
