import { ReactNode } from "react";
import ReactDOM from "react-dom";

function Portal(props: { isRootPortal?: boolean; querySelector?: string; children: ReactNode }) {
  return ReactDOM.createPortal(
    <>{props.children}</>,
    document.querySelector(props.isRootPortal ? "#root" : props.querySelector ?? "") ??
      document.body
  );
}

export default Portal;
