import { createContext, ReactNode, useContext, useState } from "react";
import { Button } from "src/components";
import { colors } from "src/styles/theme";
import { css, styled } from "styled-components";

type OpenModalFnType = (compoenent: ReactNode) => void;
type CloseModalFnType = () => void;

type ModalContextType = {
  openModal: OpenModalFnType;
  closeModal: CloseModalFnType;
};
type ModalStateContextType = {
  modalOpened: boolean;
};

const ModalContext = createContext<ModalContextType | null>(null);
const ModalStateContext = createContext<ModalStateContextType | null>(null);

export const ModalProvider = (props: any) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [modalComponent, setModalComponent] = useState<ReactNode>(null);
  const openModal: OpenModalFnType = (compoenent) => {
    setModalOpened(true);
    setModalComponent(compoenent);
  };

  const closeModal: CloseModalFnType = () => {
    setModalOpened(false);
    setTimeout(() => {
      setModalComponent(null);
    }, 150);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <ModalStateContext.Provider value={{ modalOpened }}>
        {props.children}
        {modalComponent}
      </ModalStateContext.Provider>
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const zz = useContext(ModalContext);

  return { ...zz, Modal };
};

export const useModalState = () => useContext(ModalStateContext);

function Modal(props: {
  content: ReactNode;
  title?: ReactNode;
  onConfirm?: Function;
  onClose?: Function;
  rejectConfirmCondition?: boolean;
  rejectConfirmMsg?: string;
  btnType?: "none" | "confirm" | "confirm-cancel";
}) {
  const modal = useModal();
  const modalState = useModalState();

  return (
    <>
      <BackGround
        $isOpen={modalState?.modalOpened}
        onClick={(e) => {
          modal?.closeModal && modal?.closeModal();
          props.onClose && props.onClose();
        }}
      >
        <ModalBox
          onClick={(e) => {
            // e.preventDefault();
            e.stopPropagation();
          }}
        >
          {props.title && <div className="header">{props.title}</div>}
          <div className="content">{props.content}</div>
          {(() => {
            switch (props.btnType) {
              case "none": {
                return "";
              }

              case "confirm-cancel": {
                return (
                  <div className="content-bottom d-flex gap-3">
                    <Button
                      style={{ fontWeight: "bold", fontSize: "16px" }}
                      $colorTheme="greenReverse"
                      $buttonSize="sm"
                      onClick={() => {
                        props.onClose && props.onClose();
                        modal?.closeModal && modal?.closeModal();
                      }}
                    >
                      취소
                    </Button>

                    <Button
                      style={{ fontWeight: "bold", fontSize: "16px" }}
                      $colorTheme="greenReverse"
                      $buttonSize="sm"
                      onClick={() => {
                        if (props.rejectConfirmCondition) {
                          alert(
                            props.rejectConfirmMsg ? props.rejectConfirmMsg : "진행할 수 없습니다."
                          );
                          modal?.closeModal && modal?.closeModal();
                          return;
                        }
                        props.onClose && props.onClose();
                        props.onConfirm && props.onConfirm();
                        modal?.closeModal && modal?.closeModal();
                      }}
                    >
                      확인
                    </Button>
                  </div>
                );
              }

              case "confirm":
              default: {
                return (
                  <div className="content-bottom">
                    <Button
                      style={{ fontWeight: "bold", fontSize: "16px" }}
                      $colorTheme="greenReverse"
                      $buttonSize="sm"
                      onClick={() => {
                        if (props.rejectConfirmCondition) {
                          alert(
                            props.rejectConfirmMsg ? props.rejectConfirmMsg : "진행할 수 없습니다."
                          );
                          modal?.closeModal && modal?.closeModal();
                          return;
                        }
                        props.onConfirm && props.onConfirm();
                        modal?.closeModal && modal?.closeModal();
                      }}
                    >
                      확인
                    </Button>
                  </div>
                );
              }
            }
            return "";
          })()}
        </ModalBox>
      </BackGround>
    </>
  );
}

const BackGround = styled.div<{ $isOpen?: boolean }>`
  transition: all 0.5s;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
  background-color: #00000092;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.2s;
  /* z-index: 100; */
  ${(p) =>
    !p.$isOpen &&
    css`
      animation: fade-out 0.2s;
    `}

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const ModalBox = styled.div`
  width: 90%;
  max-width: 500px;
  min-height: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;

  box-shadow: rgba(31, 31, 31, 0.35) 0px 5px 15px;

  .header {
    padding: 20px;
    font-weight: bold;
    font-size: 18px;
    border-bottom: 1px solid ${colors.gray.third};
  }
  .content {
    flex: 1;
    padding: 20px;

    display: flex;
    flex-direction: column;
  }
  .content-bottom {
    padding: 20px;

    display: flex;
    justify-content: flex-end;
  }
`;
