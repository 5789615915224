import { ReactNode, useId } from "react";
import { useModal } from "src/context/ModalContext";

export default function useAlert() {
  const md = useModal();
  const id = useId();

  // TODO: id 중복관련 이슈 발생가능성 체크

  const resume = () => {
    // document.querySelector(`#${id}`).click()
    const tar: any = document.querySelector(`#${CSS.escape(id)}`);
    if (tar) {
      tar.click();
    }
  };

  const pause = () => {
    return new Promise((resolve, reject) => {
      const button = document.createElement("button");

      button.setAttribute("id", id);
      button.textContent = "";
      document.body.appendChild(button);
      button.addEventListener("click", () => {
        document.body.removeChild(button);
        resolve(1);
      });
    });
  };

  const alert = async (
    args:
      | {
          title?: string;
          content: ReactNode;
          onClose?: () => any;
        }
      | string
  ) => {
    const isStringArgs = typeof args === "string";
    if (md?.openModal) {
      md?.openModal(
        <md.Modal
          title={isStringArgs ? "알림" : args?.title || "알림"}
          content={isStringArgs ? args : args?.content}
          onClose={() => {
            if (isStringArgs) {
              return;
            }
            if (args.onClose) {
              args.onClose();
            }
          }}
          onConfirm={() => {
            if (isStringArgs) {
              return;
            }
            if (args.onClose) {
              args.onClose();
            }
          }}
        />
      );
    }
  };

  alert.pause = pause;
  alert.resume = resume;

  return alert;
}
