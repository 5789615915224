import { ReactNode } from "react";
import { styled } from "styled-components";

function Label(props: {
  isEssential?: boolean;
  id?: string;
  content: ReactNode;
  className?: string;
}) {
  return (
    <StyledLabel htmlFor={props.id} className={props.className ?? ""}>
      {props.isEssential ? <span className="essential-mark">* </span> : null}
      {props.content}
    </StyledLabel>
  );
}

export default Label;

const StyledLabel = styled.label`
  .essential-mark {
    color: red;
  }
`;
