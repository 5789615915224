import React, { useState } from "react";
import { styled } from "styled-components";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import { colors } from "src/styles/theme";
// import usePopupPage from "../PopupPage";
// usePopupPage
import { LuZoomIn } from "react-icons/lu";
import { LuZoomOut } from "react-icons/lu";

// import Button from "../Button";
import { parseJsonStr, sleep } from "src/utils";
import SignModalContent from "src/components/YearBooking/SignModalContent";

import { bookingDummys } from "src/services/dummys";
import { createTeeshotAxiosInstance } from "src/http";
import { useNavigate } from "react-router-dom";
import { BookingClubRelDataType } from "src/types";
import { BsArrowUp } from "react-icons/bs";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useAlert, useSign } from "src/hooks";
import { RoundAccodian } from "src/components/YearBooking";
import {
  Button,
  Divider,
  SreenCoverLoading,
  usePopupPage,
  DoubleButton,
  ContentBox,
  Modal,
  ImgZoomContainer,
} from "src/components";
export default function Proposals() {
  const [bookingApplication, setBookingApplication] = useRecoilState(
    recoilStates.bookingApplication
  );
  const navigate = useNavigate();
  const [selectedClubRelId, setSelectedClubRelId] = useState<number>(0);
  const [agreeChecked, setAgreeChecked] = useState(false);
  const { closePopup } = usePopupPage("bookingProposal"); // or navigate(-1)
  const [coverLoadingState, setCoverLoadingState] = useState(false);
  const alert = useAlert();
  const [user] = useRecoilState(recoilStates.user);

  const [isSignModalOpened, setIsSignModalOpened] = useState(false);
  const [signBase64, setSignBase64] = useState<string | null>(null);

  const { PopupComponent, openPopup } = usePopupPage("announceInConfirmSection");

  const clubRels = bookingApplication?.clubRels?.filter((c) =>
    [1, 3, 7].includes(c?.year_booking_status_id)
  ); // 신청 직후 1, 조건제시 3, 클럽거절7

  const selectedRel = clubRels?.find((cr) => cr.id === selectedClubRelId);
  const selectedClubInfo = selectedRel?.club?.[0];

  const handleConfirm = async () => {
    setCoverLoadingState(true);
    try {
      const res = await createTeeshotAxiosInstance().post("/yearBooking/booking/confirmClub", {
        clubRelId: selectedClubRelId,
        signBase64,
      });

      if (!res.data?.success || !res?.data?.data?.newClubRels?.length) {
        throw new Error(res?.data?.message);
      }
      const rels = res?.data?.data?.newClubRels;

      setBookingApplication((prev) => {
        if (!prev) {
          return null;
        }
        return { ...prev, clubRels: rels };
      });
    } catch (e) {
      alert({
        content: `확정 요청 실패: ${String(e)}`,
        onClose: () => {
          alert.resume();
        },
      });
      await alert.pause();
    } finally {
      setCoverLoadingState(false);
      navigate("/");
      window.location.reload();
    }
  };

  return (
    <>
      <Container className="flex-1 d-flex fd-c">
        <div className="flex-1 d-flex fd-c gap-2">
          <ul className="proposals flex-1 d-flex fd-c gap-2">
            {clubRels?.map((cr, idx) => {
              return (
                <SelectionCard
                  setAgreeChecked={setAgreeChecked}
                  key={`selection-card-${cr.id}-${idx}`}
                  cr={cr}
                  selectedClubRelId={selectedClubRelId}
                  setSelectedClubRelId={setSelectedClubRelId}
                  // setSelectedClubInfo={props.setSelectedClubInfo}
                  // setSelectedClubInfo={setSelectedClubInfo}
                />
              );
            })}
          </ul>
          <div className={"bottom-section"}>
            {selectedClubRelId ? (
              <div className="check-line">
                <input
                  className="book-agree"
                  type="checkbox"
                  checked={agreeChecked}
                  onChange={(e) => {
                    setAgreeChecked(e.target.checked);
                  }}
                />
                <p>
                  {bookingApplication?.clubRels?.find((cr) => cr?.id === selectedClubRelId)?.name}의
                  예약조건을 확인하였고 이에 동의합니다.
                </p>
                <Button
                  $colorTheme="black"
                  onClick={() => {
                    openPopup();
                    // props.openAnnoounce();
                  }}
                >
                  모집공고
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <DoubleButton
          nagativeMargin
          left={{
            name: "취소",
            themeColor: "lightGray",
            widthRatio: 1,
            onClick: closePopup,
          }}
          right={{
            name: "골프장 확정",
            themeColor: "green",
            widthRatio: 2,
            buttonCompProps: { disabled: !(agreeChecked && selectedClubRelId) },
            onClick: () => {
              setIsSignModalOpened(true);
              return;
            },
          }}
        />
      </Container>

      {
        <PopupComponent
          headerMsg={`${selectedClubInfo?.name} 모집공고`}
          type="back"
          content={
            <PopupContainer>
              <ImgZoomContainer imgUrl={selectedClubInfo?.recruitment_notice_img_url} />
            </PopupContainer>
          }
        />
      }
      {isSignModalOpened && (
        <>
          <Modal
            title="협약서 서명"
            setModalState={setIsSignModalOpened}
            onConfirm={() => {
              // handleSubmit();
              if (!signBase64) {
                alert("서명을 입력하세요.");
                return;
              }
              handleConfirm();
            }}
            // rejectConfirmCondition={Boolean(!signBase64)}
            // rejectConfirmMsg="먼저 서명을 진행하세요."
            content={
              <>
                <p>클럽이 제안한 협약내용에 동의합니다.</p>
                <p>신청인: {user.realname}</p>
                <SignModalContent setSignBase64={setSignBase64} />
              </>
            }
          />
        </>
      )}
      {coverLoadingState && <SreenCoverLoading />}
    </>
  );
}

const Container = styled.div`
  .head-box {
    .right {
      color: ${colors.green.second};
    }
  }
  .rejection {
    color: red;
    font-weight: bold;
  }
  .bottom-section {
    display: flex;
    /* flex:1; */
    .select-comment {
    }
    .check-line {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      input.book-agree {
        width: 20px;
        transform: scale(1.5);
      }
      > p {
        flex: 1;
      }
      > button {
        flex-basis: 90px;
        align-self: stretch;
      }
    }
  }
`;

function SelectionCard(props: {
  cr: BookingClubRelDataType;
  selectedClubRelId: number;
  setSelectedClubRelId: React.Dispatch<React.SetStateAction<number>>;
  setSelectedClubInfo?: any;
  setAgreeChecked: any;
}) {
  const alert = useAlert();
  const [isDatesOpened, setIsDatesOpened] = useState(false);

  const { cr, selectedClubRelId, setSelectedClubRelId } = props;

  const handleSelect = () => {
    if (!cr.id) {
      return;
    }
    if (cr.year_booking_status_id !== 3) {
      alert("조건을 제시한 골프장만 선택가능합니다.");
      return;
    }
    if (selectedClubRelId === cr.id) {
      setSelectedClubRelId(0);
      props.setAgreeChecked(false);

      return;
    }
    if (props.setSelectedClubInfo) {
      props.setSelectedClubInfo(cr);
    }
    setSelectedClubRelId(cr.id);
  };

  const isActiveProposal = cr.year_booking_status_id === 3;
  const isRejectedProposal = cr.year_booking_status_id === 7;
  return (
    <ContentBox
      asBtn={true}
      header={
        <SelectionBoxHeaderInner
          className={`bold d-flex gap-1 jc-sb ai-c ${isActiveProposal ? "active" : ""}`}
          onClick={() => {
            handleSelect();
          }}
        >
          <div className={`d-flex gap-1`}>
            {isActiveProposal && (
              <input
                type="checkbox"
                checked={cr.id === selectedClubRelId}
                onChange={() => {}}
                name=""
                id=""
              />
            )}

            {cr.name}
            {isActiveProposal ? <span className="red">_조건 승락</span> : ""}
            {isRejectedProposal ? <span className="">_거절</span> : ""}
          </div>

          {isActiveProposal && (
            <Button
              $round
              $buttonSize="xs"
              $colorTheme="green"
              // $colorTheme="lightGreen"
              className="bold pd-1"
              // onClick={() => handleSelect()}
            >
              {"\u00a0"}
              선택하기{"\u00a0"}
            </Button>
          )}
        </SelectionBoxHeaderInner>
      }
      content={
        <SelectionBoxContent className="d-flex fd-c content-box-content">
          {(() => {
            switch (cr.year_booking_status_id) {
              case 1: {
                return (
                  <div className="d-flex fd-c gap-2 ai-c pd-2">
                    <div className="check-img-box d-flex jc-c">
                      <img src="/completed-task.png" />
                    </div>
                    <div>골프장에서 조건 검토중입니다~</div>
                  </div>
                );
              }
              case 7: {
                return <div className="d-flex jc-c ai-c pd-3">골프장에서 수락을 거절했어요.</div>;
              }
              case 3: {
                return (
                  <>
                    <div className="row">
                      <div className="row-col-head">라운드 일시</div>
                      <div className="row-col-data">
                        <div className="ml-1 fs-4 fw-n">
                          •{" "}
                          {parseJsonStr(cr?.month_arr_json_str ?? "[]")
                            ?.map((num: any) => +num)
                            ?.sort((a: number, b: number) => a - b)
                            ?.join("월, ")}
                          월{" "}
                        </div>
                        <div className="ml-1 d-flex gap-1 fw-n">
                          • <div>{JSON.parse(cr?.week_arr_json_str || "[]")?.join("주차, ")}주</div>
                          <div>
                            {JSON.parse(cr?.day_arr_json_str || "[]")?.join("요일 ")}
                            요일
                          </div>
                          <div>
                            {cr?.start_time?.slice(0, 2)}:{cr?.start_time?.slice(2, 4)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Divider />
                    <div className="d-flex">
                      <RoundAccodian className="flex-1" clubRelData={cr} />
                    </div>
                    <Divider />
                    <div className="row">
                      <div className="row-col-head">보증금</div>
                      <div className="row-col-data">
                        {cr.round_deposit ? cr.round_deposit?.toLocaleString() + " 원" : " - "}
                      </div>
                    </div>
                    <Divider />

                    <div className="row">
                      <div className="row-col-head">객단가</div>
                      <div className="row-col-data">
                        {cr.meal_price_per_person
                          ? cr.meal_price_per_person?.toLocaleString() + " 원"
                          : " - "}
                      </div>
                    </div>
                  </>
                );
              }
              default: {
                return <>nocase</>;
              }
            }
          })()}
        </SelectionBoxContent>
      }
      isSelectedBtn={cr.id === selectedClubRelId}
    />
  );
}

const PopupContainer = styled.div``;

const SelectionBoxHeaderInner = styled.div`
  background-color: #595959;
  height: 45px;
  &.active {
    background-color: ${colors.yellow.second};
    color: black;
  }
  .red {
    color: ${colors.red.first};
  }
  color: white;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  border-radius: 7.5px 7.5px 0px 0px;
  padding: 10px;
  /* padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px; */
`;

const SelectionBoxContent = styled.div`
  .row {
    display: flex;
    .row-col-head {
      font-weight: bold;
      flex-basis: 90px;
    }
    .row-col-data {
      flex: 1;
    }
  }

  .check-img-box {
    img {
      width: 50px;
    }
  }
`;
