import { colors } from "src/styles/theme";
import { styled } from "styled-components";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import { bookingDummys } from "src/services/dummys";
import Stepper from "../Stepper";
import Animation from "../Animation";
import ContentBox from "../ContentBox";

export default function WaitYearBookingProposal() {
  const navigate = useNavigate();
  const [bookingApplication] = useRecoilState(recoilStates.bookingApplication);
  const clubsCount = bookingApplication?.clubRels?.length;

  return (
    <Container className="d-flex fd-c gap-2">
      <Stepper
        className="page-pd-negative-side page-pd-negative-top"
        currentIdx={1}
        stepsXpadding="50px"
        title="골프장에서 조건 검토중입니다."
        steps={[
          { label: <>단체등록</>, icon: "group-register" },
          { label: "부킹 신청", icon: "booking-register" },
          { label: "조건 협의", icon: "select-club" },
        ]}
      />
      <div className="ani-box d-flex jc-c ai-c">
        <Animation jsonFetchPath={`${process.env.PUBLIC_URL}/checklist.json`} loop={false} />
        {/* <Lottie animationData={animationData} loop={false} />
         */}
      </div>
      <ContentBox
        colorTheme="gray"
        content={
          <div className="flex-1 d-flex fd-c ai-c jc-c gap-1">
            <p>신청하신 {clubsCount}개 클럽에서</p>
            <p>열심히 조건을 검토하고 있어요!</p>
          </div>
        }
      />
    </Container>
  );
}

const Container = styled.div`
  header {
    font-size: 16px;
    font-weight: bold;
    .left {
      color: ${colors.green.second};
    }
    .right {
      color: ${colors.gray.second};
    }
  }

  .ani-box {
    > div {
      max-width: 250px;
    }
    min-width: 1px;
  }
`;
