import React, { useState } from "react";
import DoubleButton from "../DoubleButton";
import styled from "styled-components";
import { useAlert, useGroupData, useGroupPosition } from "src/hooks";
import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import { colors } from "src/styles/theme";
import Button from "../Button";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { TeamDataType, TeamMemberDataType } from "src/types";

export default function MemberSearchPopupContents(props: {
  closeMe: () => void;
  currentTeamNumber: number;
  setTeams: React.Dispatch<React.SetStateAction<TeamDataType[] | undefined>>;
  teams?: TeamDataType[];
}) {
  const [group] = useRecoilState(recoilStates.group);
  const positions = useGroupPosition();
  const currentTeam = props?.teams?.find((t) => t.team_number === props.currentTeamNumber);
  const [activeIds, setActiveIds] = useState<number[]>(
    currentTeam?.year_booking_round_date_team_rel_booking_group_member?.map(
      (x) => x.booking_group_member_id
    ) ?? []
  );

  const alert = useAlert();

  const checkedMembers: TeamMemberDataType[] = group.members
    .filter((m) => {
      return activeIds?.includes(m.id);
    })
    .map((m) => {
      return {
        booking_group_member_id: m.id,
      };
    });

  const memebersOnCurrentRounding = props.teams
    ?.map((t) => t.year_booking_round_date_team_rel_booking_group_member)
    ?.flat();
  const navigate = useNavigate();

  return (
    <Container className="flex-1 d-flex fd-c">
      <div className="flex-1">
        {!group.members || group.members.length < 1 ? (
          <>멤버가 없습니다.</>
        ) : (
          <>
            <p>등록하세요.</p>

            <div className="list-table">
              <div className="t-header">
                <div className="item">선택</div>
                <div className="item">직책</div>
                <div className="item">이름</div>
                <div className="item">연락처</div>
              </div>
              <ul className="member-list">
                {group.members.map((m) => {
                  return (
                    <li
                      key={m.id}
                      className={`${
                        memebersOnCurrentRounding?.some(
                          (_m) => _m?.booking_group_member_id === m.id
                        )
                          ? "blocked"
                          : ""
                      }`}
                    >
                      <div
                        className="item d-flex jc-c"
                        onClick={() => {
                          if (activeIds?.includes(m.id)) {
                            setActiveIds((aids) => {
                              return aids?.filter((aid) => aid !== m.id);
                            });
                            return;
                          }
                          if (activeIds.length > 3) {
                            alert("팀당 인원은 최대 4명까지 입니다.");
                            return;
                          }
                          setActiveIds((aids) => {
                            return [...(aids ?? []), m.id];
                          });
                        }}
                      >
                        <div
                          className={`check-container flex-1 d-flex jc-c ai-c ${
                            activeIds?.includes(m.id) ? "active" : ""
                          }`}
                        >
                          <FaCheck />
                        </div>
                      </div>
                      <div className="item">
                        {positions?.find((p) => p.id === m.booking_group_member_level_id)?.name}
                      </div>
                      <div className="item">{m.name}</div>
                      <div className="item">{m.phone_number}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}
      </div>
      <DoubleButton
        nagativeMargin
        left={{
          name: "취소",
          size: "md",
          themeColor: "lightGray",
          onClick: () => {
            props.closeMe();
          },
        }}
        right={{
          name: "선택",
          size: "md",
          themeColor: "green",
          onClick: () => {
            props.setTeams((teams) => {
              const targetTeam = teams?.find((t) => t.team_number === props.currentTeamNumber);
              if (targetTeam) {
                const filterd = checkedMembers.filter((cm) => {
                  return !targetTeam.year_booking_round_date_team_rel_booking_group_member?.some(
                    (x) => x.booking_group_member_id === cm.booking_group_member_id
                  );
                });
                targetTeam.year_booking_round_date_team_rel_booking_group_member = [
                  ...(targetTeam?.year_booking_round_date_team_rel_booking_group_member ?? []),
                  ...(filterd ?? []),
                ];
              }
              return teams;
            });
            navigate(-1);
          },
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  .blocked {
    background-color: ${colors.gray.third};
    color: ${colors.gray.first};
    pointer-events: none;
  }
  .list-table {
    /* background: ; */
    display: flex;
    flex-direction: column;
    .t-header {
      background-color: ${colors.gray.second};
      display: grid;
      grid-template-columns: 0.6fr 0.8fr 1fr 1.2fr;
      > * {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    ul.member-list {
      > li {
        display: grid;
        grid-template-columns: 0.6fr 0.8fr 1fr 1.2fr;

        .check-container {
          svg {
            color: ${colors.gray.second};
          }
          /* background-color: coral; */
          &.active {
            svg {
              color: ${colors.green.first};
            }
          }
        }
      }
    }
    .item {
      border: 1px solid black;
      padding: 2.5px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      word-break: break-all;
    }
  }
`;
