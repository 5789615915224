import { useRecoilState } from "recoil";
import { recoilStates } from "src/recoil/recoilStates";
import styled from "styled-components";

export default function ApplyPaperPopupContents() {
  const [ba] = useRecoilState(recoilStates.bookingApplication);
  return <Container>ApplyPaperPopupContents</Container>;
}

const Container = styled.div``;
