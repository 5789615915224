const GAME_TYPE_DATA = [
  { id: 1, name: "신페리오" },
  { id: 2, name: "스트로크" },
  { id: 3, name: "매치플레이" },
];

const TEEBOX_TYPE_DATA = [
  { id: 1, name: "블루티" },
  { id: 2, name: "화이트티" },
  { id: 3, name: "레드티" },
  { id: 4, name: "실버티" },
];

const ETC_REQ_DATA = [
  { id: 1, name: "스코어카드" },
  { id: 2, name: "기념촬영" },
  { id: 3, name: "현수막" },
];

const AWARDS_DATA = [
  { id: 1, name: "우승" },
  { id: 2, name: "준우승" },
  { id: 3, name: "3위" },
  { id: 4, name: "행운상" },
  { id: 5, name: "특별상" },
  { id: 6, name: "메달리스트" },
  { id: 7, name: "롱게스트" },
  { id: 8, name: "니어리스트" },
  { id: 9, name: "매너상" },
  { id: 10, name: "베스트드레서", fontSize: "12px" },
];

const PAY_TYPE_DATA = [
  { id: 1, name: "전체결제" },
  { id: 2, name: "팀별결제" },
];
const MEAL_TYPE_DATA = [
  { id: 1, name: "조식" },
  { id: 2, name: "중식" },
  { id: 3, name: "석식" },
];
const ROOM_TYPE_DATA = [
  { id: 1, name: "라운드 전" },
  { id: 2, name: "라운드 후" },
];

const STATIC_DATA = {
  PAY_TYPE_DATA,
  GAME_TYPE_DATA,
  TEEBOX_TYPE_DATA,
  ETC_REQ_DATA,
  AWARDS_DATA,
  MEAL_TYPE_DATA,
  ROOM_TYPE_DATA,
};

export default STATIC_DATA;
